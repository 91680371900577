<template>
  <div v-if="showBtn()">
    <router-link :to="{name: 'Update', params: {id: getPostId()}}" class="btn btn-primary">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
        <path d="M16 5l3 3"></path>
      </svg>
      Редактировать
    </router-link>
  </div>
</template>

<script>
import {defineComponent} from "vue"
import PostPermission from "@/modules/post/services/Permission"

export default defineComponent({
  name: "UpdateBtn",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    function getPostId() {
      return props["data"]["post_id"];
    }

    function getPostChannelId() {
      return props["data"]["post_channel_id"];
    }

    function showBtn() {
      return PostPermission.isUpdate(getPostChannelId())
    }

    return {
      getPostId,
      showBtn,
    }
  },
})
</script>

<style scoped>

</style>

import Helper from "@/services/Helper"
import ApiBase from "@/modules/users/services/ApiBase"

export async function bindPhantoms(userId, options) {
    if (!Helper.isArray(options?.phantoms_ids)) {
        throw new Error("Отсутствует список фантомов")
    }

    const formData = new FormData()

    options.phantoms_ids.forEach((id) => {
        formData.append("fantom_ids[]", id)
    })

    let respBindPhantoms = await ApiBase.bindPhantomsToUser(userId, formData)

    if (respBindPhantoms.status !== 200) {
        throw new Error("Ошибка привязки фантомов к пользователю")
    }
}

import Index from "@/modules/comments/pages/index/Index"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/comments",
    name: "IndexComment",
    component: Index,
    meta: {
        middleware: authMiddleware,
    },
},]

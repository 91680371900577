import ApiBase from "@/modules/users/services/ApiBase"
import {
    bindPhantoms
} from "./common"

export default {
    data: {
        title: "Пользователь",
        btn: "Редактировать",
        page: "update",
    },
    action: ({payload, options}) => {
        return new Promise((resolve, reject) => {
            let id = payload.get("id")

            if (id === null) {
                throw new Error("ID не найден")
            }

            return ApiBase.userEdit(id, payload).then(async () => {
                await bindPhantoms(id, options)
                
                resolve({
                    id: id,
                    "msg": "Отредактирован",
                })
            }).catch(() => {
                reject({
                    id: id,
                    "msg": "Ошибка при редактировании",
                })
            }).finally(() => {})
        })
    }
}

import AuthPermission from "@/modules/auth/services/Permission"

export default new class Permission extends AuthPermission {
    isUpdate(channelId) {
        console.log(channelId)

        //todo вернуть
        //return this.hasPermission("channel", channelId)

        return true
    }

    isCreate(channelId) {
        console.log(channelId)

        //todo вернуть
        //return this.hasPermission("channel", channelId)

        return true
    }

    isDelete(channelId) {
        console.log(channelId)

        //todo вернуть
        //return this.hasPermission("channel", channelId)

        return true
    }
}

<template>
  <div ref="createModal" class="modal fade" id="modal-phantom" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle() }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card">
          <div class="card-header" v-if="isShow()">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
              <li class="nav-item">
                <button class="nav-link active" id="tabs-profile" data-bs-toggle="tab" data-bs-target="#tabs-profile-pane" type="button" role="tab" aria-controls="tabs-profile-pane" aria-selected="true">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon me-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  </svg>
                  Профиль
                </button>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <template v-if="isNotLoad()">
              <div class="tab-content">
                <div class="tab-pane fade active show" id="tabs-profile-pane" role="tabpanel" aria-labelledby="tabs-profile" tabindex="0">
                  <Profile :phantom="getPhantom()" :params="params" :modal="bootModal" @onUpdate="onUpdate" @onCreate="onCreate" />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <div class="spinner-border"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {reactive, ref, onMounted, defineComponent} from 'vue'
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import ApiBase from "@/modules/users/services/ApiBase"
import Profile from "./components/profile/Profile"

export default defineComponent({
  name: "CreateUpdate",
  components: {
    Profile,
  },
  setup(props, {emit}) {
    let show = ref(false)
    const createModal = ref(null);
    const fields = reactive(getFields())

    let load = ref(false)
    let params = ref(getParams())

    let bootModal = null;

    function getParams() {
      return {
        data: {
          title: "",
          btn: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function isNotLoad() {
      return load.value === false
    }

    function markLoad() {
      return load.value = true
    }

    function markUnload() {
      return load.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        avatar: {},
      }
    }

    function closeModal() {
      clearForm()
    }

    function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      // это нужно для того, чтобы компонент перерендеривался каждый раз при открытии
      // чтобы активной вкладкой была 1я
      // можно сделать иначе, но с компонентом табов от Tabler у меня не прокатило
      // не судите строго :)
      unmarkShow()
      setTimeout(() => {
        markShow()
      }, 1)

      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }
    }

    function setParams(val) {
      params.value = val
    }

    function create() {
      clearForm()
      markUnload()
    }

    function update(event) {
      const button = event.relatedTarget
      const id = button.getAttribute('data-id')

      ApiBase.user(id).then((resp) => {
        let item = resp.data.item

        setFields(item)
      }).catch(() => {})
      .finally(() => {
        markUnload()
      })
    }

    function setFields(item) {
      fields.id = item["id"]
      fields.name = item["name"]
      fields.avatar = {
        host: item["avatar_host"],
        name: item["avatar_name"],
      }
    }

    function preShowModal() {
      markLoad()
      resetParams()
    }

    onMounted(() => {
      bootModal = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal)
      createModal.value.addEventListener('show.bs.modal', preShowModal)
      createModal.value.addEventListener('hidden.bs.modal', closeModal)
    })

    function clearForm() {
      let items = getFields()
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function getPhantom() {
      return {
        id: fields.id,
        name: fields.name,
        avatar: {
          host: fields.avatar?.host,
          name: fields.avatar?.name,
        },
      }
    }

    function hasPhantom() {
      return fields.id !== null
    }

    function getTitle() {
      let result = ""

      result += params.value.data.title

      if (hasPhantom()) {
        result += ": "
        result += fields.name
      }

      return result
    }

    function onUpdate() {
      emit("onUpdate")
    }

    function onCreate() {
      emit("onCreate")
    }

    function isShow() {
      return show.value === true
    }

    function markShow() {
      show.value = true
    }

    function unmarkShow() {
      show.value = false
    }

    return {
      isNotLoad,
      fields,
      createModal,
      params,
      getPhantom,
      bootModal,
      getTitle,
      onUpdate,
      onCreate,
      isShow,
    }
  }
})
</script>

import {axios as http} from "@/plugins/http"

export default class ApiBase {
    static users(query) {
        return http.post("/api/v1/user/user/users", query);
    }

    static groupPermissions(query) {
        return http.post("/api/v1/user/group-permissions", query);
    }
}

import Helper from "@/services/Helper"

export default class ConvertQuery {
    static handle(query) {
        let q = {}

        if (query.search?.name !== "") {
            q = Helper.mergeObject(q, {
                "user_name": query.search?.name,
            })
        }

        if (query.search?.email !== "") {
            q = Helper.mergeObject(q, {
                "user_email": query.search?.email,
            })
        }

        if (query.permissions?.length > 0) {
            q = Helper.mergeObject(q, {
                "permission_ids": query.permissions.join(","),
            })
        }

        if (query.statuses?.enabled === true && query.statuses?.disabled !== true) {
            q = Helper.mergeObject(q, {
                "user_active": true,
            })
        } else if (query.statuses?.disabled === true && query.statuses?.enabled !== true) {
            q = Helper.mergeObject(q, {
                "user_active": false,
            })
        }

        return Helper.mergeObject(q, {
            "limit": query["limit"],
            "offset": query["offset"],
        })
    }
}
import ApiBase from "../../../services/ApiBase"
import Helper from "@/services/Helper";

export default class Api {
    static users(groupCode) {
        return ApiBase.users({
            "limit": 1000,
            "permission_group_codes": groupCode,
        }).then((resp) => {
            let items = resp.data?.item

            if (!Helper.isArray(items)) {
                throw new Error("Пользователи не получены")
            }

            return items
        }).catch(() => {})
    }
}

export const userRoleId = 1
export const editorRoleId = 2
export const moderatorRoleId = 3
export const adminRoleId = 4

export const listRoles = {
    [userRoleId]: "Пользователь",
    [editorRoleId]: "Редактор",
    [moderatorRoleId]: "Модератор",
    [adminRoleId]: "Администратор",
}

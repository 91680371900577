<template>
  <div class="filter-user" id="filter-user" v-if="isEnable()">
    <div class="filter-user__search-name">
      <label class="form-label">Поиск по имени</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="data.search.name" class="form-control" placeholder="Им пользователя">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-user__search-email">
      <label class="form-label">Поиск по email</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="data.search.email" class="form-control" placeholder="Email пользователя">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-user__permissions permissions">
      <label class="form-label">Разрешения</label>
      <Multiselect
        @change="events.onPermissions"
        v-model="data.permissions"
        mode="tags"
        placeholder="Не выбрано"
        noResultsText="Не найдено"
        :options="variantsData.permissions"
        :trackBy="'name'"
        searchable
      >
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <div class="user-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>
    <div class="filter-user__buttons">
      <label class="form-label">Статус</label>
      <div class="form-selectgroup">
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="data.statuses.enabled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
              </svg>
              Активные
            </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="data.statuses.disabled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sun-off me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 3l18 18" /><path d="M16 12a4 4 0 0 0 -4 -4m-2.834 1.177a4 4 0 0 0 5.66 5.654" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
              Не активные
            </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import './css/style.css'
import {defineComponent, onMounted, reactive} from "vue"
import ApiBase from "@/modules/users/services/ApiBase"
import Multiselect from "@/plugins/multiselect"

export default defineComponent({
  name: "Items",
  components: {
    Multiselect,
  },
  props: {
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const variantsData = reactive({
      permissions: [],
    })

    let data = reactive({
      search: {
        name: props.params.search?.name ?? '',
        email: props.params.search?.email ?? '',
      },
      permissions: props.params.permissions ?? [],
      enable: true,
      statuses: {
        enabled: props.params.statuses?.enabled ?? true,
        disabled: props.params.statuses?.disabled ?? false,
      },
    })

    const events = {
      onPermissions: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        data.permissions = value
        onFilter()
      },
      onSearch: () => {
        onFilter()
      },
      onStatuses: () => {
        onFilter()
      },
    }

    function permissionsMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.permissions.push({
          value: obj["permission_id"],
          name: obj["permission_name"],
        })
      }
    }

    function permissions() {
      return ApiBase.allPermissions().then((resp) => {
        let items = resp.data.item
        
        permissionsMultiSelect(items)
      })
    }

    function onFilter() {
      emit("onFilter", getResult())
    }

    function getResult() {
      return {
        search: {
          name: data.search.name,
          email: data.search.email,
        },
        statuses: {
          enabled: data.statuses?.enabled,
          disabled: data.statuses?.disabled,
        },
        permissions: data.permissions,
      }
    }

    function isEnable() {
      return data.enable === true
    }

    onMounted(() => {
      permissions()
      onFilter()
    })

    return {
      events,
      data,
      variantsData,
      isEnable,
    }
  }
})
</script>

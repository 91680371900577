export default {
    getPermissionId: (item) => {
        return item["permission_id"]
    },
    getSourceId: (item) => {
        return item["source_id"]
    },
    getPermissionName: (item) => {
        return item["permission_name"]
    },
    getPermissionCode: (item) => {
        return item["permission_code"]
    },
}

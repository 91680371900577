<template>
  <div class="filter-phantom" id="filter-phantom" v-if="isEnable()">
    <div class="filter-phantom__search-name">
      <label class="form-label">Поиск по имени</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="data.search.name" class="form-control" placeholder="Имя фантома">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-phantom__buttons">
      <label class="form-label">Статус</label>
      <div class="form-selectgroup">
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="data.statuses.enabled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
              </svg>
              Активные
            </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="data.statuses.disabled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sun-off me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 3l18 18" /><path d="M16 12a4 4 0 0 0 -4 -4m-2.834 1.177a4 4 0 0 0 5.66 5.654" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
            Не активные
          </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="data.statuses.deleted" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="4" y1="7" x2="20" y2="7"></line>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import './css/style.css'
import {defineComponent, onMounted, reactive} from "vue"

export default defineComponent({
  name: "Items",
  components: {},
  props: {
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const variantsData = reactive({
      permissions: [],
    })

    let data = reactive({
      search: {
        name: props.params.search?.name ?? '',
      },
      enable: true,
      statuses: {
        enabled: props.params.statuses?.enabled ?? true,
        disabled: props.params.statuses?.disabled ?? false,
        deleted: props.params.statuses?.deleted ?? false,
      },
    })

    const events = {
      onSearch: () => {
        onFilter()
      },
      onStatuses: () => {
        onFilter()
      },
    }

    function onFilter() {
      emit("onFilter", getResult())
    }

    function getResult() {
      return {
        search: {
          name: data.search.name,
        },
        statuses: {
          enabled: data.statuses?.enabled,
          disabled: data.statuses?.disabled,
          deleted: data.statuses?.deleted,
        },
      }
    }

    function isEnable() {
      return data.enable === true
    }

    onMounted(() => {
      onFilter()
    })

    return {
      events,
      data,
      variantsData,
      isEnable,
    }
  }
})
</script>

import ApiBase from "@/modules/users/services/ApiBase"
import {
    bindUsers
} from "./common"

export default {
    data: {
        title: "Фантом",
        btn: "Редактировать",
        page: "update",
    },
    action: ({payload, options}) => {
        return new Promise((resolve, reject) => {
            let id = payload.get("id")

            if (id === null) {
                throw new Error("ID не найден")
            }

            return ApiBase.phantomEdit(id, payload).then(async () => {
                await bindUsers(id, options)

                resolve("Отредактирован")
            }).catch(() => {
                reject("Ошибка при редактировании")
            }).finally(() => {})
        })
    }
}

import Helper from "@/services/Helper"

export default class ConvertQuery {
    static handle(query) {
        let q = {}

        if (query.search?.name !== "") {
            q = Helper.mergeObject(q, {
                "fantom_name": query.search?.name,
            })
        }

        if (query.statuses?.enabled === true && query.statuses?.disabled !== true) {
            q = Helper.mergeObject(q, {
                "fantom_active": true,
            })
        } else if (query.statuses?.disabled === true && query.statuses?.enabled !== true) {
            q = Helper.mergeObject(q, {
                "fantom_active": false,
            })
        }

        if (query.statuses?.deleted) {
            q = Helper.mergeObject(q, {
                "fantom_delst": true,
            })
        }

        return Helper.mergeObject(q, {
            "limit": query["limit"],
            "offset": query["offset"],
        })
    }
}
<template>
  <label class="form-label">Активность</label>
  <div class="form-check form-switch d-inline-block">
    <input class="form-check-input" type="checkbox" v-model="fields.active" :checked="isActive()" @change="change"/>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "vue"
import ApiBase from "@/modules/users/services/ApiBase"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Active",
  components: {},
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const fields = reactive(getFields())

    onMounted(() => {
      updateFields()
    })

    function updateFields() {
      fields.id = props.user.id
      fields.active = props.user.active
    }

    function getFields() {
      return {
        id: null,
        active: false,
      }
    }

    function isActive() {
      return fields.active === true
    }

    function change(e) {
      let checked = false

      if (e.target.checked) {
        checked = true
      }

      ApiBase.userChangeActive(fields.id, {
        "user_active": checked,
      }).then(() => {
        if (checked) {
          fields.active = true
          Notify.success("Пользователь активирован")
        } else {
          fields.active = false
          Notify.success("Пользователь деактивирован")
        }
      }).catch((err) => {
        fields.active = !checked
        Notify.error('Ошибка смены активности', err)
      })
    }

    return {
      isActive,
      change,
      fields,
    }
  }
})
</script>

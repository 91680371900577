<template>
  <div v-if="isShow()" class="pt-3">
    <div class="row">
      <div class="row align-items-center">
        <div class="col-auto" v-if="hasAvatar()">
          <span class="avatar avatar-xl" :style="{backgroundImage: 'url('+ getAvatar(300) +')'}"></span>
        </div>
        <div class="col-auto">
          <label class="form-label">Аватар</label>
          <input type="file" class="form-control" accept=".png, .jpg, .jpeg, .gif" @change="handleImage" v-if="isShowFileUpload()">
          <div v-if="hasAvatar()" class="mt-2">
            <a href="#" @click=deleteAvatar()>
              Удалить аватар
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, ref, watch} from "vue"
import Image from "@/services/Image"
import ApiBase from "@/modules/users/services/ApiBase"
import Notify from "@/services/Notify"
import Helper from "@/services/Helper"

export default defineComponent({
  name: "Avatar",
  components: {},
  props: {
    phantom: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    let phantom = ref({})
    let showFileUpload = ref(true)

    watch(() => props.phantom, (query) => {
      phantom.value = query
    })

    function hasPhantom() {
      return !Helper.isEmptyObject(phantom.value) && phantom.value.id !== null
    }

    function getAvatar(w = null, h = null) {
      return Image.handle(phantom.value.avatar?.host, phantom.value.avatar?.name, w, h)
    }

    function handleImage(e) {
      const file = e.target.files[0]
      const reader = new FileReader()

      if (!file) {
        resetInput()
        new Error("Отсутствует картинка")
        return
      }

      if (!file.type.match('image.*')) {
        resetInput()
        Notify.error('Выберите картинку')
        return
      }

      reader.onload = (e) => {
        const image = document.createElement('img');
        const tempDiv = document.createElement('div')

        tempDiv.style.display = 'none'
        tempDiv.appendChild(image);

        document.body.appendChild(tempDiv)

        image.src = e.target.result
        image.onload = () => {
          const width = image.width
          const height = image.height

          document.body.removeChild(tempDiv)

          if (width !== height) {
            resetInput()
            Notify.error('Выберите квадратную картинку!')
          } else {
            uploadAvatar(file)
            resetInput()
          }
        };
      }

      reader.readAsDataURL(file)
    }

    function setAvatar(host, name) {
      phantom.value.avatar = {
        host: host,
        name: name,
      }
    }

    function uploadAvatar(image) {
      let formData = new FormData()

      formData.append('user_id', phantom.value.id)
      formData.append('image', image)

      ApiBase.userChangeAvatar(formData).then((resp) => {
        let item = resp.data.item

        let host = item?.user_avatar_host
        let name = item?.user_avatar_name

        if (!host || !name) {
          throw new Error("Отсутствуют данные аватара")
        }

        setAvatar(host, name)

        Notify.success("Аватар загружен");
      }).catch((err) => {
        Notify.error('Не удалось загрузить аватар', err)
      })
    }

    function hasAvatar() {
      return phantom.value.avatar?.host && phantom.value.avatar?.name
    }

    function deleteAvatar() {
      if (confirm("Вы уверены, что хотите удалить аватар?")) {
        ApiBase.userDeleteAvatar(phantom.value.id).then(() => {
          setAvatar(null, null)

          Notify.success("Аватар удален")
        }).catch((err) => {
          Notify.error('Не удалось удалить аватар', err)
        })
      }
    }

    function isShow() {
      return hasPhantom()
    }


    function resetInput() {
      showFileUpload.value = false
      nextTick(() => {
        showFileUpload.value = true
      })
    }

    function isShowFileUpload() {
      return showFileUpload.value
    }

    return {
      hasPhantom,
      handleImage,
      getAvatar,
      deleteAvatar,
      hasAvatar,
      isShow,
      isShowFileUpload,
    }
  }
})
</script>
  
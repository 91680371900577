<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="{ name: 'Users' }" v-if="isView()">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-users"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
      </span>
      <span class="nav-link-title">
        Пользователи
      </span>
    </router-link>
  </li>
</template>

<script>
import Permission from "@/modules/catalog/services/Permission"
import {onMounted, ref} from "vue"

export default {
  name: "Users",
  setup() {
    let noPermission = ref(false)

    onMounted(() => {
      checkPermission()
    })

    function checkPermission() {
      if (!Permission.hasPermissions("users")) {
        setNoPermission()
      }
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isView() {
      return !noPermission.value
    }

    return {
      isView,
    }
  },
}
</script>

<style scoped>

</style>
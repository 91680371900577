<template>
  <metainfo></metainfo>
  <template v-if="isForbiddenPage()">
    <NoPermission />
  </template>
  <template v-else>
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h2 class="page-title">
              Магазины
            </h2>
          </div>
          <div class="col-auto" v-if="isShowPermissionBtn()">
            <PermissionsGroup :data='{
              user_filter: {
                roles: [editorRoleId],
              },
              category: "shops",
              object_id: -1,
            }' />
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" data-bs-toggle="modal" data-type="create" :data-bs-target="'#modal-shop'">
              <IconCirclePlus />
              Создать
            </button>
          </div>
          <div class="col-auto">
            <router-link :to="{name: 'ShopsCategories'}" class="btn btn-green">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="icon icon-tabler icons-tabler-outline icon-tabler-category">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 4h6v6h-6z"/>
                <path d="M14 4h6v6h-6z"/>
                <path d="M4 14h6v6h-6z"/>
                <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
              </svg>
              Категории
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="mb-3">
          <ShopFilter @onFilter="onFilter" :params="getParams()" />
        </div>
        <div ref="scrollComponent">
          <ShopList :query="getQuery()" @onRender="onRender" :clear-result="getClearResult()" />
        </div>
      </div>
    </div>
    <Teleport to="body">
      <Modal @onUpdate="onUpdate" @onCreate="onCreate" @onDelete="onDelete"/>
    </Teleport>
  </template>
</template>
<script>
import {defineComponent, onMounted, onUnmounted, ref} from 'vue'
import ShopFilter from "@/modules/shops/pages/shops/components/filter/Index"
import ShopList from "@/modules/shops/pages/shops/components/list/Index"
import Modal from '@/modules/shops/pages/shops/components/modals/create_update/Index'
import IconCirclePlus from '@/modules/shops/icons/IconCirclePlus'
import {useMeta} from "vue-meta"
import Helper from "@/services/Helper"
import PermissionsGroup from "@/components/permissions_group/Index"
import ShopsPermission from "@/modules/shops/services/Permission"
import Permission from "@/modules/users/services/Permission"
import NoPermission from "@/modules/auth/pages/no_permission/Index"
import {editorRoleId} from "@/modules/auth/services/role"

export default defineComponent({
  name: "Items",
  components: {
    ShopFilter,
    ShopList,
    Modal,
    IconCirclePlus,
    PermissionsGroup,
    NoPermission,
  },
  setup() {
    useMeta({title: "Магазины"})

    const scrollComponent = ref(null)

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countPosts = ref(0)
    let isLoading = ref(false)
    let noPermission = ref(false)
    let scroll = () => {
      isLoading.value = true
      offset.value += 18

      onLoadMore(offset.value)
    }
    let params = {
      search: "",
      categories: [],
      enable: true,
    }

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countPosts.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countPosts.value = 0

      q = Helper.mergeObject(q, {
        "limit": 18,
        "offset": offset.value,
      })

      q = Helper.clone(q)

      query.value = q
    }

    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) {
        return false
      }

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    const handleScroll = () => {
      if (eofResult.value) return

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    onMounted(() => {
      if (!checkPermission()) {
        return
      }

      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })

    function checkPermission() {
      if (!Permission.hasPermissions("shops")) {
        setNoPermission()
        return false
      }

      return true
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isForbiddenPage() {
      return noPermission.value
    }

    function getParams() {
      return params
    }

    function getQuery() {
      return query.value
    }

    function getClearResult() {
      return clearResult.value
    }

    function onUpdate() {
      onFilter({})
    }

    function onCreate() {
      onFilter({})
    }

    function onDelete() {
      onFilter({})
    }

    function isShowPermissionBtn() {
      return ShopsPermission.isShowPermissionBtn()
    }

    return {
      getParams,
      getQuery,
      getClearResult,
      onRender,
      onFilter,
      scrollComponent,
      onUpdate,
      onCreate,
      onDelete,
      isShowPermissionBtn,
      isForbiddenPage,
      editorRoleId,
    }
  }
})

</script>

<style scoped src="./css/style.css"></style>

export default {
    getPermissionId: (item) => {
        return item["permission_id"]
    },
    getPermissionName: (item) => {
        return item["permission_name"]
    },
    getUserChecked: (item) => {
        return item["user_checked"]
    },
    getSourceId: (item) => {
        return item["source_id"]
    },
}

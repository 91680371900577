import Users from "@/modules/users/pages/users/Users"
import Phantoms from "@/modules/users/pages/phantoms/Phantoms"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
        middleware: authMiddleware,
    }
}, {
    path: "/users/phantoms",
    name: "Phantoms",
    component: Phantoms,
    meta: {
        middleware: authMiddleware,
    }
},]

<template>
  <metainfo></metainfo>
  <div id="post-card">
    <template v-if="conditions.isNoPermission()">
      <NoPermission />
    </template>

    <template v-else-if="conditions.isReadyPage()">
      <div class="container-xl">
        <div class="page-header d-print-none">
          <div class="row g-2 align-items-center">
            <div class="col-sm">
              <h2 class="page-title">
                <Title :data="{
                  post_id: getPostId(),
                  channel_id: bootData.channel_id,
                  status: bootData.status,
                  is_del: bootData.del,
                  scheduled_published: bootData.scheduled_published.value
                }" @onCountSellStatistic="onCountSellTitle" @onClickChainStatistic="onClickChainTitle" />
              </h2>
            </div>
            <div class="col-auto">
              <div class="me-1">
                <ViewBtn :data="{
                  post_id: getPostId(),
                }" />
              </div>
            </div>
            <div class="col-auto" v-if="conditions.isShowPostInChain()">
              <div class="me-1">
                <PostInChain :post-id="chainObject.getId()" :type-id="chainObject.getTypeId()" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row">
            <div class="col">
              <PostComplaints :post-id="getPostId()" v-if="false" />
            </div>
          </div>

          <CommentModeration :payload="{
            categories: ['new'],
            limit: 20,
            title: 'Новые комментарии',
            filter: {
              post_id: getPostId(),
              object_type_id: commentModerationObject.getTypeId()
            }
          }" />

          <div class="row">
            <div class="col-md-8">
              <Transactions :data="transactions" />
              <div class="mb-3">
                <Editor :validations="validations" :content="bootData.content" @onChange="events.onChangeContent" />
              </div>
              <div class="mb-3" v-if="conditions.isViewPostSaveBlock()">
                <ProcessSave :process="isSavingProcess()" />
              </div>
            </div>
            <div class="col-md-4">
              <Info :post-id="getPostId()" :key="updateInfo" />

              <div class="mt-2">
                <Shops :data="{
                  val: bootData.shop_id,
                  validations: validations
                }" @onChange="events.onChangeShopId" />
              </div>

              <div class="mt-2">
                <Image :has-video="conditions.hasVideo()" :src="bootData.image" :post-id="getPostId()" @onChange="events.onChangeImage" @onDelete="events.onDeleteImage" />
              </div>

              <ImagePosition :validations="validations" :enable="conditions.isViewImagePosition()" :position="bootData.image_position" @onChange="events.onChangeImagePosition" />

              <div class="mt-2">
                <Video :has-image="conditions.hasImage()" :validations="validations" :src="bootData.video" :post-id="getPostId()" @onChange="events.onChangeVideo" @onDelete="events.onDeleteVideo" />
              </div>

              <div class="mt-2" v-if="conditions.isShowScheduledRemove()">
                <ScheduledRemoved :validations="validations" :datetime="bootData.scheduled_removed" @onChange="events.onChangeScheduledRemoved" />
              </div>

              <div class="mt-2">
                <Button :validations="validations" :data="{
                  name: bootData.button.name,
                  link: bootData.button.link
                }" @onChange="events.onChangeButton" />
              </div>

              <div class="mt-2">
                <IsPartnerLinks :validations="validations" :data="{
                  enable: bootData.is_partner_links,
                }" @onChange="events.onChangeIsPartnerLinks" />
              </div>

              <div class="mt-2">
                <TypeCommerce :data="{
                  val: bootData.type_commerce,
                  validations: validations
                }" @onChange="events.onChangeTypeCommerce" />
              </div>

              <div class="mt-2">
                <CommentAllow :validations="validations" :data="{
                  enable: bootData.comment_allow,
                }" @onChange="events.onChangeCommentAllow" />
              </div>

              <div class="card mt-2" v-if="conditions.isShowBtns()">
                <div class="card-body">
                  <template v-if="conditions.isShowPrePublishedBtn()">
                    <PostRepublished :query="getQuery()" @onRepublished="events.onPostRepublished()" />
                  </template>
                  <template v-if="conditions.isShowCancelBtn()">
                    <PostCancel :data="{
                      post_id: getPostId(),
                    }" @onCancel="events.onPostCancel()" />
                  </template>
                  <template v-if="conditions.isShowSeeInBot()">
                    <SeeInBot :query="getQuery()" :post-id="getPostId()" @onError="events.onErrorSeeInBot" @onSuccess="events.onSuccessSeeInBot" />
                  </template>
                  <template v-if="conditions.isShowScheduledPublishedModalBtn()">
                    <a href="#" class="btn btn-warning w-100 mb-3" :class="{ 'disabled': conditions.isDisabledScheduledPublishedModalBtn() }" data-bs-toggle="modal" data-bs-target="#scheduled-published" @click="events.onShowModal()">Опубликовать</a>
                  </template>
                  <template v-if="conditions.isShowSaveBtn()">
                    <button class="btn btn-green w-100 mb-3" :class="{ 'disabled': conditions.isDisabledSaveBtn() }" @click="events.onSaveNow">
                      <span class="spinner-border spinner-border-sm me-2" v-show="conditions.isShowLoadingSaveBtn()"></span>
                      Сохранить
                    </button>
                  </template>
                  <template v-if="conditions.isShowRemoveBtn()">
                    <PostRemove :data="{
                      post_id: getPostId(),
                      post_in_chain: bootData.has_in_chain,
                      status: bootData.status
                    }" @onPostRemove="events.onPostRemove()" />
                  </template>
                </div>
              </div>
            </div>
            <template v-if="conditions.isUpdatePost()">
              <div class="mt-2">
                <PostRefPosts :data="{
                  id: chainObject.getId(),
                  channel_id: chainObject.getChannelId(),
                  status: chainObject.getStatus(),
                  del: chainObject.getDel(),
                }" :type-id="chainObject.getTypeId()" />
              </div>
              <div class="mt-2">
                <Composites :data="{
                  postId: getPostId(),
                  hasInChain: bootData.has_in_chain,
                }" />
              </div>
              <div class="mt-2">
                <PostRefLinks :data="{
                  postId: getPostId()
                }" />
              </div>
              <div class="mt-2">
                <CommentModeration :payload="{
                  categories: ['approved', 'rejected', 'removed'],
                  limit: 20,
                  force_show: true,
                  title: 'Промодерированные комментарии',
                  filter: {
                    post_id: getPostId(),
                    object_type_id: commentModerationObject.getTypeId()
                  }
                }" />
              </div>
            </template>
          </div>

          <div class="modal modal-blur fade" id="scheduled-published" tabindex="-1" role="dialog" aria-hidden="true" v-show="conditions.isViewPublishedWindow()">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Опубликовать</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                </div>
                <div class="modal-body">
                  <div class="accordion">
                    <div class="accordion-item">
                      <h2 class="accordion-header" @click="accordion.onClick">
                        <button class="accordion-button" :class="{ 'collapsed': accordion.item1 === false }" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true">
                          Опубликовать отложено
                        </button>
                      </h2>
                      <div id="collapse-1" class="accordion-collapse collapse" :class="{ 'show': accordion.item1 === true }" data-bs-parent="#accordion-example" style="">
                        <div class="accordion-body pt-0">
                          <ScheduledPublished :validations="validations" :enable="bootData.scheduled_published.enable" :datetime="bootData.scheduled_published.value" @onChange="events.onChangeScheduledPublished" />
                          <button class="btn btn-warning w-100" :class="{ 'disabled': conditions.isDisabledScheduledPublishedBtn() }" @click="events.onSaveScheduled(true)">
                            <span class="spinner-border spinner-border-sm me-2" v-show="conditions.isShowLoadingScheduledPublishedBtn()"></span>
                            Опубликовать отложено
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" @click="accordion.onClick">
                        <button class="accordion-button" :class="{ 'collapsed': accordion.item2 === false }" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false">
                          Опубликовать сейчас
                        </button>
                      </h2>
                      <div id="collapse-2" class="accordion-collapse collapse" :class="{ 'show': accordion.item2 === true }" data-bs-parent="#accordion-example" style="">
                        <div class="accordion-body pt-0">
                          <button class="btn btn-danger w-100" :class="{ 'disabled': conditions.isDisabledPublishedNowBtn() }" @click="events.onPublishedNow(true)">
                            <span class="spinner-border spinner-border-sm me-2" v-show="conditions.isShowLoadingPublishedNowBtn()"></span>
                            Опубликовать сейчас
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="page-body">
        <div class="container-xl">
          <div class="row">
            <div class="mb-3">
              <div class="loader-posts">
                <div class="spinner-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Image from "@/modules/post/components/image/Image"
import Video from "@/modules/post/components/video/Video"
import Title from "@/modules/post/components/title/Title"
import Api from "./services/Api"
import {defineComponent, onMounted, ref, reactive, watch} from "vue"
import {useMeta} from "vue-meta"
import {useRoute} from "vue-router"
import {isSavingProcess, autosaveInit, addStackChanges, autosaveStop} from "@/modules/post/pages/edit/services/auto_save"
import createDraftInit from "@/modules/post/pages/edit/services/create_draft"
import ImagePosition from "@/modules/post/components/image_position/ImagePosition"
import Editor from "@/modules/post/components/editor/Editor"
import ScheduledRemoved from "@/modules/post/components/scheduled_removed/ScheduledRemoved"
import ScheduledPublished from "@/modules/post/components/scheduled_published/ScheduledPublished"
import SeeInBot from "@/modules/post/components/see_in_bot/SeeInBot"
import ProcessSave from "@/modules/post/components/process_save/ProcessSave"
import Helper from "@/services/Helper"
import PostRefPosts from "@/components/object_chains/PostRefPosts"
import PostRemove from "@/modules/post/components/remove/Remove"
import PostCancel from "@/modules/post/components/cancel/Cancel"
import PostRepublished from "@/modules/post/components/republished/Republished"
import Composites from "@/modules/post/components/composites/Composites"
import PostRefLinks from "@/modules/post/components/ref_links/RefLinks"
import PostInChain from "@/components/post_in_chain/PostInChain"
import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"
import PostPermission from "@/modules/post/services/Permission"
import NoPermission from "@/modules/auth/pages/no_permission/Index"
import Info from "@/modules/post/components/info/Index"
import ViewBtn from "@/modules/post/components/view_btn/ViewBtn"
import IsPartnerLinks from "@/modules/post/components/is_partner_links/IsPartnerLinks"
import CommentAllow from "@/modules/post/components/comment_allow/CommentAllow"
import TypeCommerce from "@/modules/post/components/type_commerce/TypeCommerce"
import Transactions from "@/modules/post/components/transactions/Transactions"
import PostComplaints from "@/modules/post/components/complaints/PostComplaints"
import Button from "@/modules/post/components/button/Index"
import Shops from "@/modules/post/components/shops/Index"
import cfg from "@/services/Config"
import CommentModeration from "@/components/comment_moderation/CommentModeration"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Index",
  components: {
    CommentModeration,
    Transactions,
    TypeCommerce,
    ProcessSave,
    SeeInBot,
    ScheduledRemoved,
    ScheduledPublished,
    Editor,
    ImagePosition,
    Image,
    PostRefPosts,
    Composites,
    PostRefLinks,
    Title,
    PostRemove,
    PostInChain,
    NoPermission,
    Info,
    ViewBtn,
    IsPartnerLinks,
    Video,
    PostCancel,
    PostRepublished,
    PostComplaints,
    Button,
    Shops,
    CommentAllow,
  },
  setup() {
    const route = useRoute()
    let readyPage = ref(false)
    let validations = ref({})
    let postId = ref(null)
    let saving = ref(false)
    let closeModal = ref(null)
    let error = ref(false)
    let noPermission = ref(false)
    let updateInfo = ref(0)

    let chainObject = {
      getId: () => {
        return getPostId()
      },
      getChannelId: () => {
        return bootData.channel_id
      },
      getStatus: () => {
        if (bootData.status === "published") {
          return "published"
        } else if (bootData.status === "draft") {
          return "draft"
        } else if (bootData.status === "deleted") {
          return "deleted"
        } else if (bootData.status === "scheduled") {
          return "scheduled"
        } else if (bootData.status === "prePublished") {
          return "prePublished"
        } else if (bootData.status === "preDeleted") {
          return "preDeleted"
        } else if (bootData.status === "canceled") {
          return "canceled"
        } else {
          throw Error("Неопределен статус")
        }
      },
      getDel: () => {
        if (bootData.del === 1) {
          return 1
        } else if (bootData.del === 0) {
          return 0
        } else {
          throw Error("Неопределен признак удаления")
        }
      },
      getTypeId: () => {
        return cfg._("object_chains", "types", "post")
      }
    }

    let commentModerationObject = {
      getTypeId: () => {
        return cfg._("comment_moderation", "types", "post")
      }
    }

    let bootData = reactive({
      content: null,
      image: null,
      image_position: null,
      video: null,
      scheduled_removed: null,
      scheduled_published: {
        value: null,
        enable: null,
      },
      status: null,
      del: null,
      channel_id: getChannelId(),
      has_in_chain: null,
      is_partner_links: 1,
      comment_allow: 1,
      type_commerce: null,
      shop_id: -1,
      button: {
        name: "",
        link: "",
      },
    })

    let setters = {
      setImage: (val) => {
        bootData.image = val
      },
      setImagePosition: (val) => {
        bootData.image_position = val
      },
      setVideo: (val) => {
        bootData.video = val
      },
      setContent: (val) => {
        bootData.content = val
      },
      setScheduledDelete: (val) => {
        bootData.scheduled_removed = val
      },
      setScheduledPublished: (val) => {
        bootData.scheduled_published.value = val
      },
      setScheduledPublishEnable: (val) => {
        bootData.scheduled_published.enable = val
      },
      setButton: (val) => {
        bootData.button = {
          name: val["name"],
          link: val["link"],
        }
      },
      setIsPartnerLinks: (val) => {
        bootData.is_partner_links = val
      },
      setCommentAllow: (val) => {
        bootData.comment_allow = val
      },
      setTypeCommerce: (val) => {
        bootData.type_commerce = val
      },
      setShopId: (val) => {
        bootData.shop_id = val
      },
    }

    let accordion = reactive({
      item1: true,
      item2: false,
      onClick() {
        accordion.item1 = !accordion.item1
        accordion.item2 = !accordion.item2
      },
    })

    let conditions = {
      statuses: {
        isDraft: () => {
          return bootData.status === "draft"
        },
        isPublished: () => {
          return bootData.status === "published"
        },
        isScheduled: () => {
          return bootData.status === "scheduled"
        },
        isPrePublished: () => {
          return bootData.status === "prePublished"
        },
        isPreDeleted: () => {
          return bootData.status === "preDeleted"
        },
        isCanceled: () => {
          return bootData.status === "canceled"
        },
        isDeleted: () => {
          return bootData.status === "deleted"
        },
      },
      isReadyPage: () => {
        return readyPage.value === true
      },
      isNoPermission: () => {
        return noPermission.value === true
      },
      isViewPostSaveBlock: () => {
        return hasPostId() && conditions.statuses.isDraft() && !conditions.statuses.isDeleted()
      },
      isPostRemoved: () => {
        return conditions.statuses.isDeleted()
      },
      isNotPostRemoved: () => {
        return !conditions.isPostRemoved()
      },
      isNotError: () => {
        return !isError()
      },
      isShowCancelBtn: () => {
        return hasPostId() && (conditions.statuses.isPreDeleted() || conditions.statuses.isPrePublished())
      },
      isShowRemoveBtn: () => {
        return hasPostId() && !conditions.statuses.isDeleted() && !conditions.statuses.isCanceled() && !conditions.statuses.isPrePublished()
      },
      isShowSeeInBot: () => {
        return hasPostId() && !conditions.statuses.isDeleted() && !conditions.statuses.isCanceled() && !conditions.statuses.isPrePublished() && !conditions.statuses.isPreDeleted()
      },
      isShowScheduledPublishedModalBtn: () => {
        return (conditions.statuses.isDraft() || conditions.statuses.isScheduled()) && !conditions.statuses.isDeleted() && !conditions.statuses.isCanceled()
      },
      isDisabledScheduledPublishedModalBtn: () => {
        return !hasPostId()
      },
      isShowSaveBtn: () => {
        return (conditions.statuses.isPublished()
          || conditions.statuses.isScheduled()
          || conditions.isPostRemoved()) && !conditions.statuses.isCanceled()
      },
      isDisabledSaveBtn: () => {
        return conditions.isSaving()
      },
      isShowLoadingSaveBtn: () => {
        return conditions.isSaving()
      },
      isShowPrePublishedBtn: () => {
        return hasPostId() && conditions.statuses.isPrePublished()
      },
      isViewPublishedWindow: () => {
        return (conditions.statuses.isDraft() || conditions.statuses.isScheduled()) && hasPostId()
      },
      isDisabledScheduledPublishedBtn: () => {
        return conditions.isSaving()
      },
      isShowLoadingScheduledPublishedBtn: () => {
        return conditions.isSaving()
      },
      isDisabledPublishedNowBtn: () => {
        return conditions.isSaving()
      },
      isShowLoadingPublishedNowBtn: () => {
        return conditions.isSaving()
      },
      isRunAutoSave: () => {
        return conditions.statuses.isDraft() && !conditions.statuses.isDeleted()
      },
      isSaving: () => {
        return saving.value === true
      },
      isUpdatePost: () => {
        return hasPostId()
      },
      hasImage: () => {
        return bootData.image?.post_image_id !== null && bootData.image !== null
      },
      isViewImagePosition: () => {
        return conditions.hasImage() && (conditions.statuses.isDraft() || conditions.statuses.isScheduled())
      },
      isShowPostInChain: () => {
        return hasPostId() && conditions.isNotPostRemoved()
      },
      isShowBtns: () => {
        return hasPostId() && conditions.isNotError()
      },
      isShowScheduledRemove: () => {
        return hasPostId() && conditions.isNotPostRemoved()
      },
      hasVideo: () => {
        return bootData.video?.post_video_id !== null && bootData.video !== null
      }
    }

    let events = {
      onShowModal: () => {
        accordion.item1 = true
        accordion.item2 = false
      },
      onChangeImage: (val) => {
        setters.setImage(val)
      },
      onDeleteImage: () => {
        setters.setImage(null)
        setters.setImagePosition(null)
      },
      onChangeImagePosition: (val) => {
        let newVal = val !== null ? parseInt(val) : null
        setters.setImagePosition(newVal)
        addStackChanges({image_position: newVal})
      },
      onChangeVideo: (val) => {
        setters.setVideo(val)
      },
      onDeleteVideo: () => {
        setters.setVideo(null)
      },
      onChangeContent: (content) => {
        setters.setContent(content)
        addStackChanges({content: JSON.stringify(content !== null ? content : "")})
      },
      onChangeScheduledRemoved: (val) => {
        setters.setScheduledDelete(val)
        addStackChanges({scheduled_removed: val})
      },
      onChangeScheduledPublished: (val) => {
        setters.setScheduledPublished(val)
        addStackChanges({scheduled_published: val})
      },
      onSaveScheduled: (fromModal = false) => {
        events.onSave(fromModal, "scheduled")
      },
      onPublishedNow: (fromModal = false) => {
        events.onSave(fromModal, "published")
      },
      onSaveNow: (fromModal = false) => {
        if (confirm("Вы действительно хотите сохранить?")) {
          events.onSave(fromModal, "save")
          runRenderInfo()
        }
      },
      onSave: (fromModal, type) => {
        autosaveStop()
        setValidation([])
        startSaving()

        factorySave(getQuery(), type).then(() => {
          if (fromModal === true) {
            closeModalWindow()
          }
          getItem(getPostId()).then((resp) => {
            initBootData(resp)
            runRenderInfo()
          }).then(() => {
            if (type === "scheduled") {
              Notify.success("Запланировано")
            } else if (type === "published") {
              Notify.success("Опубликовано")
            } else if (type === "save") {
              Notify.success("Сохранено")
            }
          })
        }).catch((resp) => {
          setValidation(resp.response.data?.validations)
        }).finally(() => {
          stopSaving()
        })
      },
      onPostRemove: () => {
        getItem(getPostId()).then((resp) => {
          autosaveStop()
          initBootData(resp)
          runRenderInfo()
        })
      },
      onPostCancel: () => {
        getItem(getPostId()).then((resp) => {
          initBootData(resp)
          runRenderInfo()
        })
      },
      onPostRepublished: () => {
        getItem(getPostId()).then((resp) => {
          initBootData(resp)
          runRenderInfo()
        })
      },
      onErrorSeeInBot: (errors) => {
        setValidation(errors)
      },
      onSuccessSeeInBot: () => {
        clearValidations()
      },
      onChangeButton: (val) => {
        setters.setButton(val)
        addStackChanges({
          button: {
            name: val["name"],
            link: val["link"],
          }
        })
      },
      onChangeIsPartnerLinks: (val) => {
        setters.setIsPartnerLinks(val)
        addStackChanges({is_partner_links: val})
      },
      onChangeCommentAllow: (val) => {
        setters.setCommentAllow(val)
        addStackChanges({comment_allow: val})
      },
      onChangeTypeCommerce: (val) => {
        setters.setTypeCommerce(val)
        addStackChanges({type_commerce: val})
      },
      onChangeShopId: (val) => {
        setters.setShopId(val)
        addStackChanges({shop_id: val})
      },
    }

    let transactions = reactive({
      element: {},
      post_id: null,
    })

    watch(() => accordion.item1, (val) => {
      setters.setScheduledPublishEnable(val)
    })

    watch(() => bootData.scheduled_published.enable, () => {
      //accordion.item1 = (val === true)
      //accordion.item2 = (val === false)
    })

    watch(bootData, (val) => {
      PostStoreCommon.handle({
        "channel_id": val.channel_id,
        "route_name": "PostCreateUpdate",
      })
    })

    onMounted(() => {
      autosaveStop()

      if (route.params.id) {
        setPostId(parseInt(route.params.id))
      }

      if (conditions.isUpdatePost()) {
        useMeta({title: "Редактирование поста"})
        getItem(getPostId()).then(async (resp) => {
          await initBootData(resp)

          if (!isUpdatePermission(bootData.channel_id)) {
            setNoPermission()
          }
          readyPageOn()
          if (conditions.isRunAutoSave()) {
            createAutosave(getPostId())
          }
        })
      } else {
        useMeta({title: "Создание поста"})

        let thisTypeId = cfg._("object_chains", "types", "post")

        createDraft().then(async (ID) => {
          await getItem(ID).then((resp) => {
            initBootData(resp)
          })
          if (!isUpdatePermission(bootData.channel_id)) {
            setNoPermission()
          }
          bindChainPost(
              ID,
              route.query["in_chain_object_id"],
              thisTypeId,
              route.query["in_chain_type_id"],
          )
          createAutosave(ID)
          setPostId(ID)
          changeUrl(ID)
          readyPageOn()
        })
      }
    })

    function runRenderInfo() {
      updateInfo.value += 1;
    }

    function isUpdatePermission(channelId) {
      return PostPermission.isUpdate(channelId)
    }

    function markError() {
      error.value = true
    }

    function clearValidations() {
      validations.value = false
    }

    function isError() {
      return error.value === true
    }

    function bindChainPost(thisObjectId, thatObjectId, thisTypeId, thatTypeId) {
      if (thatObjectId === undefined) {
        return false
      }

      Api.chainBindInPost(thisObjectId, thatObjectId, thisTypeId, thatTypeId).catch(() => {
        markError()
      })
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function createAutosave(ID) {
      autosaveInit({
        stack: {
          "content": bootData.content !== null ? JSON.stringify(bootData.content) : null,
          "image_position": bootData.image_position,
          "scheduled_removed": bootData.scheduled_removed,
          "scheduled_published": bootData.scheduled_published.value,
          "is_partner_links": bootData.is_partner_links,
          "comment_allow": bootData.comment_allow,
          "type_commerce": bootData.type_commerce,
          "button": bootData.button,
          "shop_id": bootData.shop_id,
        },
        stackMap: {
          "content": "content",
          "image_position": "image_position",
          "scheduled_removed": "scheduled_removed",
          "scheduled_published": "scheduled_published",
          "is_partner_links": "is_partner_links",
          "comment_allow": "comment_allow",
          "type_commerce": "type_commerce",
          "shop_id": "shop_id",
        },
        ID: ID,
      })
    }

    function createDraft() {
      return createDraftInit({
        "channel_id": parseInt(route.params["channel_id"]),
      })
    }

    function getChannelId() {
      return parseInt(route.params["channel_id"])
    }

    function initBootData(data) {
      bootData.status = data["post_status"]

      bootData.content = JSON.parse(data["post_content_source"] ?? "{}")

      if (data["post_image_host"] !== null && data["post_image_name"] !== null) {
        bootData.image = {
          host: data["post_image_host"],
          name: data["post_image_name"],
          id: data["post_image_id"],
        }
      }

      if (data["post_video_host"] !== null && data["post_video_name"] !== null) {
        bootData.video = {
          host: data["post_video_host"],
          name: data["post_video_name"],
          id: data["post_video_id"],
        }
      }

      bootData.image_position = data["post_image_position"]

      bootData.scheduled_removed = data["post_scheduled_removed"]

      bootData.scheduled_published = {
        value: data["post_scheduled_published"],
        enable: data["post_scheduled_published"] !== null,
      }

      bootData.del = data["post_del"]

      bootData.channel_id = data["post_channel_id"]

      bootData.has_in_chain = data["post_has_in_chain"]

      bootData.is_partner_links = data["post_is_partner_links"]

      bootData.comment_allow = data["post_comment_allow"]

      bootData.type_commerce = data["post_type_commerce"]

      bootData.button = {
        name: data["button"]["name"],
        link: data["button"]["link"],
      }

      bootData.shop_id = data["post_shop_id"]
    }

    function getItem(ID) {
      return Api.item(ID)
    }

    function changeUrl(post_id) {
      window.history.pushState(null, null, "/post/edit/" + post_id)
    }

    function hasPostId() {
      return postId.value !== null
    }

    function getPostId() {
      return postId.value
    }

    function setPostId(post_id) {
      postId.value = post_id
    }

    function factorySave(q, type) {
      if (type === "scheduled") {
        return Api.scheduled(q)
      } else if (type === "published") {
        return Api.published(q)
      } else if (type === "save") {
        if (conditions.isPostRemoved()) {
          return Api.saveDelete(q)
        } else {
          return Api.save(q)
        }

      } else {
        throw Error("Неверный тип")
      }
    }

    function getQuery(q = {}) {
      let query = {
        "post_id": getPostId(),
        "content": bootData.content !== null ? JSON.stringify(bootData.content) : null,
        "image_position": bootData.image_position,
        "scheduled_removed": bootData.scheduled_removed,
        "scheduled_published": bootData.scheduled_published.value,
        "is_partner_links": bootData.is_partner_links,
        "comment_allow": bootData.comment_allow,
        "type_commerce": bootData.type_commerce,
        "button": bootData.button,
        "shop_id": bootData.shop_id,
      }

      return Helper.mergeObject(query, q);
    }

    function setValidation(data) {
      validations.value = data
    }

    function startSaving() {
      saving.value = true
    }

    function stopSaving() {
      saving.value = false
    }

    function closeModalWindow() {
      closeModal.value.click()
    }

    function onCountSellTitle(payload) {
      transactions.element = payload["element"]
      transactions.post_id = payload["post_id"]
    }

    function onClickChainTitle(payload) {
      transactions.element = payload["element"]
      transactions.post_id = payload["post_id"]
    }

    return {
      commentModerationObject,
      conditions,
      events,
      bootData,
      isSavingProcess,
      getPostId,
      validations,
      accordion,
      closeModal,
      updateInfo,
      getQuery,
      onCountSellTitle,
      transactions,
      onClickChainTitle,
      chainObject,
    }
  },
})
</script>

<style src="./css/style.css" scoped></style>

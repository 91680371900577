<template>
  <div ref="createModal" class="modal fade" id="modal-user" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">{{ getTitle() }}</span>
          <span class="modal-subtitle mb-0" v-if="hasCreated()">Зарегистрирован: <span v-html="fields.createdAt"></span></span>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card">
          <div class="card-header" v-if="isShow()">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
              <li class="nav-item">
                <button class="nav-link active" id="tabs-profile" data-bs-toggle="tab" data-bs-target="#tabs-profile-pane" type="button" role="tab" aria-controls="tabs-profile-pane" aria-selected="true">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon me-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  </svg>
                  Профиль
                </button>
              </li>
              <li class="nav-item" v-if="isUpdateAction()">
                <button class="nav-link" id="tabs-permissions" data-bs-toggle="tab" data-bs-target="#tabs-permissions-pane" type="button" role="tab" aria-controls="tabs-permissions-pane" aria-selected="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-shield-check me-2">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11.46 20.846a12 12 0 0 1 -7.96 -14.846a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.09 7.06" /><path d="M15 19l2 2l4 -4" />
                  </svg>
                  Разрешения
                </button>
              </li>
              <li class="nav-item" v-if="isUpdateAction()">
                <button class="nav-link" id="tabs-group-permissions" data-bs-toggle="tab" data-bs-target="#tabs-group-permissions-pane" type="button" role="tab" aria-controls="tabs-group-permissions-pane" aria-selected="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-manual-gearbox me-2">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M5 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M12 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M19 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M5 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M12 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M5 8l0 8" />
                    <path d="M12 8l0 8" />
                    <path d="M19 8v2a2 2 0 0 1 -2 2h-12" />
                  </svg>
                  Группы разрешений
                </button>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <template v-if="isNotLoad()">
              <div class="tab-content">
                <div class="tab-pane fade active show" id="tabs-profile-pane" role="tabpanel" aria-labelledby="tabs-profile" tabindex="0">
                  <Profile :user="getUser()" :params="params" :modal="bootModal" @onUpdate="onUpdate" @onUpdateAvatar="onUpdateAvatar" @onCreate="onCreate" />
                </div>
                <div class="tab-pane fade" id="tabs-permissions-pane" role="tabpanel" aria-labelledby="tabs-permissions" tabindex="0">
                  <Permissions :user="getUser()" />
                </div>
                <div class="tab-pane fade" id="tabs-group-permissions-pane" role="tabpanel" aria-labelledby="tabs-group-permissions" tabindex="0">
                  <GroupPermissions :user="getUser()" />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <div class="spinner-border"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {reactive, ref, onMounted, defineComponent} from 'vue'
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import ApiBase from "@/modules/users/services/ApiBase"
import DTBackend from "@/services/DTBackend"
import Profile from "./components/profile/Profile"
import Permissions from "./components/permissions/Permissions"
import GroupPermissions from "./components/group_permissions/GroupPermissions"

export default defineComponent({
  name: "CreateUpdate",
  components: {
    Profile,
    Permissions,
    GroupPermissions,
  },
  setup(props, {emit}) {
    let show = ref(false)
    const createModal = ref(null)
    const fields = reactive(getFields())

    let load = ref(false)
    let params = ref(getParams())

    let bootModal = ref(null);

    function getParams() {
      return {
        data: {
          title: "",
          btn: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function isNotLoad() {
      return load.value === false
    }

    function markLoad() {
      return load.value = true
    }

    function markUnload() {
      return load.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        email: '',
        login: '',
        telegramId: null,
        avatar: {},
        createdAt: null,
        permissions: [],
        active: false,
        role: 0,
      }
    }

    function closeModal() {
      clearForm()
    }

    function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      // это нужно для того, чтобы компонент перерендеривался каждый раз при открытии
      // чтобы активной вкладкой была 1я
      // можно сделать иначе, но с компонентом табов от Tabler у меня не прокатило
      // не судите строго :)
      unmarkShow()
      setTimeout(() => {
        markShow()
      }, 1)

      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }
    }

    function setParams(val) {
      params.value = val
    }

    function create() {
      clearForm()
      markUnload()
    }

    function update(event) {
      const button = event.relatedTarget
      const id = button.getAttribute('data-id')

      ApiBase.user(id).then((resp) => {
        let item = resp.data.item

        setFields(item)
      }).catch(() => {})
      .finally(() => {
        markUnload()
      })
    }

    function setFields(item) {
      let dt = new DTBackend(item["created"])
      let createdAt = dt.get("%D.%M.%y <span>%H:%I</span>")

      fields.id = item["id"]
      fields.name = item["name"]
      fields.email = item["email"]
      fields.login = item["login"]
      fields.telegramId = item["telegram_id"]
      fields.avatar = {
        host: item["avatar_host"],
        name: item["avatar_name"],
      }
      fields.createdAt = createdAt
      fields.permissions = item["permissions"]
      fields.active = item["active"]
      fields.role = item["role_id"]
    }

    function preShowModal() {
      markLoad()
      resetParams()
    }

    onMounted(() => {
      bootModal.value = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal)
      createModal.value.addEventListener('show.bs.modal', preShowModal)
      createModal.value.addEventListener('hidden.bs.modal', closeModal)
    })

    function clearForm() {
      let items = getFields()
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function getUser() {
      return {
        id: fields.id,
        name: fields.name,
        email: fields.email,
        login: fields.login,
        telegram_id: fields.telegramId,
        avatar: {
          host: fields.avatar?.host,
          name: fields.avatar?.name,
        },
        permissions: fields.permissions,
        active: fields.active,
        role: fields.role,
      }
    }

    function hasCreated() {
      return fields.createdAt !== "" && fields.createdAt !== null
    }

    function hasUser() {
      return fields.id !== null
    }

    function isUpdateAction() {
      return hasUser()
    }

    function getTitle() {
      let result = ""

      result += params.value.data.title

      if (hasUser()) {
        result += ": "
        result += fields.name
      }

      return result
    }

    function onUpdate() {
      emit("onUpdate")
    }

    function onCreate() {
      emit("onCreate")
    }

    function onUpdateAvatar() {
      emit("onUpdate")
    }

    function isShow() {
      return show.value === true
    }

    function markShow() {
      show.value = true
    }

    function unmarkShow() {
      show.value = false
    }

    return {
      hasCreated,
      isNotLoad,
      fields,
      createModal,
      params,
      getUser,
      isUpdateAction,
      getTitle,
      bootModal,
      onUpdate,
      onCreate,
      onUpdateAvatar,
      isShow,
    }
  }
})
</script>

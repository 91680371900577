export default {
    getId: (item) => {
        return item["id"]
    },
    getAvatarHost: (item) => {
        return item["avatar_host"]
    },
    getAvatarName: (item) => {
        return item["avatar_name"]
    },
    getActive: (item) => {
        return item["active"]
    },
    getName: (item) => {
        return item["name"]
    },
    getRole: (item) => {
        return item["role"]
    },
}

<template>
  <template v-if="isPending()">
    <div class="loader-posts">
      <div class="spinner-border"></div>
    </div>
  </template>
  <template v-else>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Назначенные пользователи</h3>
        </div>
        <div class="card-body">
          <div class="row g-3">
            <template v-if="hasUsers()">
              <div class="col-6" v-for="user in getUsers()" :key="row.getId(user)">
                <div class="row g-3 align-items-center">
                  <a href="#" class="col-auto">
                    <span class="avatar" :style="{backgroundImage: 'url('+ row.getAvatar(user, 40) +')'}"></span>
                  </a>
                  <div class="col text-truncate">
                    <span class="remove-link">
                      <a href="#" data-bs-toggle="modal" data-type="update" :data-bs-target="'#modal-permissions'" :data-group-code="getGroupCode()" :data-user="getUser(user)" class="text-reset text-truncate">{{ row.getName(user) }}</a>
                      <div class="text-muted text-truncate mt-n1"><b>ID</b>: {{ row.getId(user) }}, info@merkulov-pavel.ru</div>
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <span>Пользователей пока нет</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue"
import Api from "./services/Api"
import element from "./services/element"
import UserImage from "../../services/UserImage"
import User from "../../services/User"

export default defineComponent({
  name: "AssignUsers",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    let users = ref({})
    let pending = ref(true)
    let row = {
      getId(item) {
        return element.getId(item)
      },
      getName(item) {
        return element.getName(item)
      },
      getAvatar(item, w = null, h = null) {
        return UserImage.handle(element.getAvatarHost(item), element.getAvatarName(item), w, h)
      },
    }
    
    onMounted(() => {
      if (getGroupCode() !== "") {
        fetchUsers(getGroupCode())
      }
    })

    watch(() => props.data, () => {
      fetchUsers(getGroupCode())
    })

    function getGroupCode() {
      if (props.data?.group_code === undefined || props.data?.group_code === null) {
        return ""
      }

      return props.data?.group_code
    }

    async function fetchUsers(groupCode) {
      startPending()
      Api.users(groupCode).then((items) => {
        users.value = items
      })
      .finally(() => {
        stopPending()
      })
    }

    function startPending() {
      return pending.value = true
    }

    function stopPending() {
      return pending.value = false
    }

    function isPending() {
      return pending.value === true
    }

    function hasUsers() {
      return users.value !== undefined && users.value.length > 0
    }

    function getUsers() {
      return users.value
    }

    function getUser(user) {
      return User.getData({
        id: row.getId(user),
        name: row.getName(user),
      })
    }

    return {
      users,
      isPending,
      row,
      hasUsers,
      getUsers,
      getUser,
      getGroupCode,
    }
  },
})
</script>

import ApiBase from "@/modules/users/services/ApiBase"

export default {
    data: {
        title: "Пользователь",
        page: "create",
    },
    action: ({payload}) => {
        return new Promise((resolve, reject) => {
            return ApiBase.userCreate(payload).then(() => {
                resolve("Создан")
            }).catch(() => {
                reject("Ошибка при создании")
            }).finally(() => {})
        })
    }
}

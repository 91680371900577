<template>
  <span id="post-channel-permission" v-if="isReadyPage()">
    <span class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#permissions-group-modal">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-pocket">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h14a2 2 0 0 1 2 2v6a9 9 0 0 1 -18 0v-6a2 2 0 0 1 2 -2" /><path d="M8 11l4 4l4 -4" />
      </svg>
      Разрешения
    </span>
    <div ref="createModal" class="modal fade" id="permissions-group-modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Управление разрешениями</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" data-type="close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="mb-3">
                <FindUsers :data='{"roles": getUserRoles(), "group_code": getGroupCode()}' :key="updates" />
              </div>
              <AssignUsers :data='{"group_code": getGroupCode()}' :key="updates" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
  <Teleport to="body">
    <Permissions />
  </Teleport>
</template>

<script>
import {defineComponent, onMounted, ref, nextTick, onUnmounted} from "vue"
import "./css/style.css"
import FindUsers from "./components/find_users/Index"
import AssignUsers from "./components/assign_users/Index"
import cfg from "@/services/Config"
import Helper from "@/services/Helper"
import {eventBus} from "@/plugins/event_bus/event_bus"
import {eventBindUndind} from "@/components/permissions_group/components/permissions/services/data"
import Permissions from './components/permissions/Index'

export default defineComponent({
  name: "PermissionsGroup",
  components: {
    Permissions,
    FindUsers,
    AssignUsers,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const createModal = ref(null)
    let bootModal = null

    let permission = ref(null)
    let readyPage = ref(false)
    let forceCloseModal = ref(false)
    let groupCode = ref(null)

    let updates = ref(0)

    function modalInit() {
      if (createModal.value !== null) {
        bootModal = new bootstrap.Modal(createModal.value)
        createModal.value.addEventListener('hide.bs.modal', hideModal)
        createModal.value.addEventListener('show.bs.modal', preShowModal)
      }
    }

    function preShowModal() {
      let code = getGroupCodePermission(getCategory(), getObjectId())

      setGroupCode(code)

      updates.value++
    }

    function setGroupCode(val) {
      groupCode.value = val
    }

    function getGroupCode() {
      return groupCode.value
    }

    function getUserFilter() {
      let filter = props.data?.user_filter

      if (filter === undefined) {
        return null
      }

      return filter
    }

    function getUserRoles() {
      let userFilter = getUserFilter()

      if (userFilter === null) {
        return []
      }

      if (!Helper.isArray(userFilter?.roles)) {
        return []
      }

      return userFilter?.roles
    }

    function getGroupCodePermission(category, objectId) {
      if (category === undefined || objectId === undefined) {
        return ""
      }

      let list = cfg._("category_groups", category)
      
      if (list === undefined) {
        return ""
      }
      
      if (list["-1"] !== undefined) {
        return list["-1"]
      }

      if (list[objectId] === undefined) {
        return ""
      }
      
      return list[objectId]
    }

    // todo почему вызывается 2 раза?
    onMounted(() => {
      eventBus.on(eventBindUndind, updateEvent)

      readyPageOn()

      nextTick(() => {
        modalInit()
      });
    })

    onUnmounted(() => {
      eventBus.off(eventBindUndind, updateEvent)
    })

    function updateEvent() {
      updates.value++
    }

    function closeModal() {
      forceCloseModal.value = true
      bootModal.hide()
    }

    function hideModal(event) {
      if (forceCloseModal.value === true) {
        forceCloseModal.value = false
      } else {
        event.preventDefault();
      }
    }

    function getObjectId() {
      return props.data?.object_id
    }

    function getCategory() {
      return props.data?.category
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function isReadyPage() {
      return readyPage.value
    }

    return {
      permission,
      isReadyPage,
      createModal,
      bootModal,
      closeModal,
      getGroupCode,
      getUserRoles,
      updates,
    }
  },
})
</script>

import RouteRegister from "@/plugins/router/services/RouteRegister"
import routers from '@/modules/comments/routers'
import plugin from "./plugins/index"
import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "./stores"

export default {
    install: (app) => {
        StoreRegister.handle("comment", stores)
        RouteRegister.handle(routers)

        app.use(plugin);
    }
}

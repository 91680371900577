import ApiBase from "@/modules/users/services/ApiBase"
import Helper from "@/services/Helper"
import {
    bindUsers
} from "./common"

export default {
    data: {
        title: "Создание фантома",
        btn: "Создать",
        page: "create",
    },
    action: ({payload, options}) => {
        return new Promise((resolve, reject) => {
            return ApiBase.phantomCreate(payload).then(async (resp) => {
                let id = resp?.data?.item

                if (!Helper.isInt(id)) {
                    throw new Error("Не получен ID созданного фантома")
                }

                await bindUsers(id, options)

                resolve("Создан")
            }).catch(() => {
                reject("Ошибка при создании")
            }).finally(() => {})
        })
    }
}

import {axios as http} from "@/plugins/http"

export default new class ApiBase {
    session() {
        return new Promise(function (resolve, reject) {
            http.post("/api/v1/user/user/session").then((response) => {
                resolve(response)
            }).catch(function (error) {
                reject(error)
            });
        });
    }

    refresh(payload) {
        return new Promise(function (resolve, reject) {
            http.post("/api/v1/user/user/refresh", {
                "token": payload["token"],
                "client": payload["client"],
            }).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                reject(error)
            });
        });
    }

    logout(payload) {
        return new Promise(function (resolve, reject) {
            http.post("/api/v1/user/user/logout", {
                "client": payload["client"],
            }).then(function (response) {
                resolve(response)
            }).catch(function (response) {
                reject(response)
            })
        })
    }

    allPermissions() {
        return http.post("/api/v1/user/user/permissions")
    }
}

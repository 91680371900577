<template>
  <div id="post-in-chain">
    <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#post-in-chain-modal">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
      </svg>
      Создать в цепочку
    </button>

    <div class="modal modal-blur fade" id="post-in-chain-modal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Создать в цепочку</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row g-3">
                <div class="col-lg-4" v-for="channel of getChannels()" :key="channel.name_id">
                  <div class="row g-3 align-items-center mb-3 link" @click.prevent="onClick(channel.name_id)">
                    <span class="col-auto">
                      <span class="avatar br-4" :style="{ backgroundImage: 'url(/assets/static/channels/' + channel.img + ')' }"></span>
                    </span>
                    <div class="col text-truncate">
                      <span class="text-reset d-block text-truncate">
                        {{ channel.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref} from "vue"
import {useRouter} from "vue-router/dist/vue-router"
import PostPermission from "@/modules/post/services/Permission"

export default defineComponent({
  name: "PostInChain",
  props: {
    postId: {
      type: Number,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    let closeModal = ref(null)

    let channels = [
      {
        name: "Молния",
        name_id: 3,
        img: "molniya.jpg",
      },
      {
        name: "Aliexpress",
        name_id: 4,
        img: "ali.jpg",
      },
      {
        name: "Халявщики",
        name_id: 2,
        img: "hlv.jpg",
      },
      {
        name: "Travel",
        name_id: 7,
        img: "travel.jpg",
      },
      {
        name: "Live",
        name_id: 9,
        img: "live.jpg",
      },
      {
        name: "Нашли дешевле",
        name_id: 8,
        img: "cheaper.jpg",
      },
      {
        name: "DailySales",
        name_id: 5,
        img: "daily.jpg",
      },
      {
        name: "Эконом",
        name_id: 10,
        img: "econom.jpg",
      },
      {
        name: "Haunt",
        name_id: 11,
        img: "haunt.jpg",
      },
      {
        name: "Ultima",
        name_id: 12,
        img: "ultima.jpg",
      },
    ]

    function getChannels() {
      return channels.filter((item) => {
        return PostPermission.isCreate(item["name_id"])
      })
    }

    function onClick(nameId) {
      let routeData = router.resolve({
        name: 'Create',
        params: {
          channel_id: nameId
        },
        query: {
          in_chain_object_id: props.postId, // для текущего поста
          in_chain_type_id: props.typeId, // для типа текущего поста
        }
      });

      window.open(routeData.href, '_blank');

      closeModalWindow()
    }

    function closeModalWindow() {
      closeModal.value.click()
    }

    return {
      getChannels,
      onClick,
      closeModal,
    }
  },
})
</script>

<style scoped></style>
<template>
  <span class="me-1" v-if="isShowPermissionBtn() && isPage.posts()">
     <PermissionsGroup :data='{
        user_filter: {
          roles: [editorRoleId],
        },
       category: "channels",
       object_id: channelId,
     }' />
  </span>
  <span class="me-1" v-if="isShowCreateBtn()">
    <router-link class="btn btn-primary" :to="{name: 'Create', params: {channel_id: channelId}}">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
      </svg>
      Создать
    </router-link>
  </span>
  <span class="me-1">
    <template v-if="isPage.posts()">
      <router-link class="btn btn-dark" :to="{name: 'PostsDrafts', params: {channel_id: channelId}}">
        Черновики
      </router-link>
    </template>
     <template v-if="isPage.drafts()">
      <router-link class="btn btn-dark" :to="{name: 'Posts', params: {channel_id: channelId}}">
        Посты
      </router-link>
    </template>
  </span>
</template>

<script>
import {defineComponent} from "vue"
import PostPermission from "@/modules/post/services/Permission"
import PermissionsGroup from "@/components/permissions_group/Index"
import {editorRoleId} from "@/modules/auth/services/role"

export default defineComponent({
  name: "Actions",
  components: {
    PermissionsGroup,
  },
  props: {
    channelId: {
      type: Number,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let isPage = {
      posts: () => {
        return props.pageName === "posts"
      },
      drafts: () => {
        return props.pageName === "drafts"
      },
    }

    function isShowCreateBtn() {
      return PostPermission.isCreate(props.channelId)
    }

    function isShowPermissionBtn() {
      return PostPermission.isShowPermissionBtn()
    }

    return {
      isPage,
      isShowCreateBtn,
      isShowPermissionBtn,
      editorRoleId,
    }
  },
})
</script>

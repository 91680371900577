import ApiBase from "@/modules/users/services/ApiBase"

export default class Api {
    static permissionGroups() {
        return ApiBase.permissionGroups().then((resp) => {
            return resp.data
        })
    }

    static groupPermissions(query) {
        return ApiBase.groupPermissions(query).then((resp) => {
            return resp.data
        })
    }
}

import ApiBase from "@/modules/users/services/ApiBase"

export default {
    data: {
        title: "Пользователь",
        page: "update",
    },
    action: ({payload}) => {
        return new Promise((resolve, reject) => {
            let id = payload.get("id")

            if (id === null) {
                throw new Error("ID не найден")
            }

            return ApiBase.userEdit(id, payload).then(() => {
                resolve("Отредактирован")
            }).catch(() => {
                reject("Ошибка при редактировании")
            }).finally(() => {})
        })
    }
}

import ApiBase from "@/modules/users/services/ApiBase"
import Helper from "@/services/Helper"
import {
    bindPhantoms
} from "./common"

export default {
    data: {
        title: "Создание пользователя",
        btn: "Создать",
        page: "create",
    },
    action: ({modal, payload, options}) => {
        return new Promise((resolve, reject) => {
            return ApiBase.userCreate(payload).then(async (resp) => {
                let id = resp?.data?.item

                if (!Helper.isInt(id)) {
                    throw new Error("Не получен ID созданного пользователя")
                }

                await bindPhantoms(id, options)

                modal.hide()

                resolve({
                    id: id,
                    "msg": "Создан",
                })
            }).catch(() => {
                reject({
                    id: null,
                    "msg": "Ошибка при создании",
                })
            }).finally(() => {})
        })
    }
}

import Multiselect from '@vueform/multiselect'

import '@vueform/multiselect/themes/default.css'
import './style.css'

export function getIds(items, key) {
    key = key ?? "value"

    return items.map((item) => {
        return item[key]
    })
}

export function getStruct(items, value, id) {
    id = id ?? "id"

    return items.map((item) => {
        return {
            "value": item[id],
            "name": item[value],
        }
    })
}

export default Multiselect

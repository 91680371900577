<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="mb-3">
        <label class="form-label">Имя</label>
        <input type="text" class="form-control" placeholder="Имя пользователя" v-model="fields.name"/>
        <div v-if="v$.name.$invalid">
          <span :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}"></span>
          <div class="invalid-feedback" v-for="error of v$.name.$silentErrors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6" v-if="isUpdateAction()">
      <div class="mb-3">
        <label class="form-label">Telegram ID</label>
        <span v-if="fields.telegramId > 0"><b>{{ fields.telegramId }}</b></span>
        <span v-else><i>Отсутствует</i></span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="mb-3">
        <label class="form-label">E-mail</label>
        <input type="email" class="form-control" placeholder="E-mail" v-model="fields.email"/>
        <div v-if="v$.email.$invalid">
          <span :class="{'is-invalid': v$.email.$invalid && v$.email.$dirty}"></span>
          <div class="invalid-feedback" v-for="error of v$.email.$silentErrors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6" v-if="isUpdateAction()">
      <div class="mb-3">
        <label class="form-label">Логин</label>
        <span v-if="fields.login"><b>{{ fields.login }}</b></span>
        <span v-else><i>Отсутствует</i></span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="mb-3">
        <div class="user-create-update__phantoms">
          <label class="form-label">Назначенные фантомы</label>
          <Multiselect
              @change="events.onPhantoms"
              v-model="fields.phantoms"
              mode="tags"
              placeholder="Не выбрано"
              noResultsText="Не найдено"
              :trackBy="'name'"
              :filter-results="false"
              :min-chars="1"
              :resolve-on-load="false"
              :object="true"
              :delay="500"
              noOptionsText="Не найдено"
              :options="async (query) => {
                return await searchPhantoms(query)
              }"
              searchable
          >
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
                <div class="user-name">{{ option.name }}</div>
                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                <span class="multiselect-tag-remove-icon"></span>
              </span>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <div class="option-user">
                <div class="user-name"> {{ option.name }}</div>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
    <div class="col-lg-6" v-if="isUpdateAction()">
      <div class="mb-3">
        <Active :user="getUser()" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="mb-3">
        <div class="user-create-update__role">
          <label class="form-label">Роль</label>
          <Multiselect @change="events.onRoles" v-model="fields.role" mode="single" placeholder="Не выбрано" noResultsText="Результатов не найдено" :searchable="true" :close-on-select="true" :options="variantsData.roles" :trackBy="'title'" max="1">
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label option-item">
                <div class="item-image-wrap" v-if="value.image">
                  <img class="item-image" :src="value.image">
                </div>
                <div class="item-name"> {{ value.name }}</div>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <div class="option-item">
                <div class="item-image-wrap" v-if="option.image">
                  <img class="item-image" :src="option.image">
                </div>
                <div class="item-name"> {{ option.name }}</div>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <Avatar @onUpdate="onUpdateAvatar" :user="getUser()"/>
  </div>

  <div class="mt-3"></div>

  <div class="card-footer bg-transparent mt-auto p-0 pt-3">
    <div class="row align-items-center">
      <div class="col" v-if="isCreateAction()">После создания пользователя появится больше полей.</div>
      <div :class='{"col-auto": isCreateAction()}'>
        <div class="btn-list justify-content-end">
          <button class="btn btn-primary ms-auto" @click="handleForm" :class="{'disabled': isPending()}">
            <span class="spinner-border spinner-border-sm me-2" v-show="isPending()"></span>
            {{ params.data.btn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, onMounted, ref} from "vue"
import Avatar from "../avatar/Avatar"
import {required} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import Notify from "@/services/Notify"
import Multiselect, {getIds, getStruct} from "@/plugins/multiselect"
import Helper from "@/services/Helper"
import Active from "./components/active/Active"
import ApiBase from "@/modules/users/services/ApiBase"
import {listRoles} from "@/modules/auth/services/role"

export default defineComponent({
  name: "Profile",
  components: {
    Avatar,
    Multiselect,
    Active,
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    modal: {
      type: Object,
      required: true
    },
  },
  setup(props,{emit}) {
    let pending = ref(false)

    const variantsData = reactive({
      phantoms: [],
      roles: [],
    })

    const fields = reactive(getFields())

    const rules = {
      id: {},
      email: {},
      login: {},
      name: {
        required,
      },
      telegramId: {},
      role: {},
    }

    const v$ = useVuelidate(rules, fields)

    const events = {
      onPhantoms: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        fields.phantoms = value
      },
      onRoles: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        fields.role = value
      },
    }

    async function searchPhantoms(search) {
      let query = {
        "fantom_name": search,
        "limit": 1000,
      }

      let resp = await ApiBase.phantoms(query)

      return resp.data.item.map(item => ({
        value: item.id,
        name: item.name,
      }));
    }

    function roles() {
      let variants = [];

      Object.entries(listRoles).forEach(([key, val]) => {
        variants.push({
          "id": key,
          "name": val,
        })
      })

      rolesMultiSelect(variants)
    }

    function rolesMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.roles.push({
          value: Number(obj["id"]),
          name: obj["name"],
        })
      }
    }

    onMounted(() => {
      roles()
      
      if (props?.user?.id) {
        updateFields()
      }
    })

    async function getPhantoms(id) {
      let phantoms = await ApiBase.getPhantomsToUser(id)
      let items = phantoms?.data?.item

      if (!items) {
        throw new Error("Ошибка при получении фантомов к пользователю")
      }

      return getStruct(items, "name")
    }

    async function updateFields() {
      let id = props.user.id

      fields.id = id
      fields.name = props.user.name
      fields.email = props.user.email
      fields.login = props.user.login
      fields.telegramId = props.user.telegram_id
      fields.avatar = {
        host: props.user.avatar?.host,
        name: props.user.avatar?.name,
      }
      fields.active = props.user.active
      fields.role = props.user.role
      fields.phantoms = await getPhantoms(id)
    }

    async function validate(fn) {
      if (!await v$.value.$validate()) {
        return
      }

      await fn()
    }

    function isPending() {
      return pending.value === true
    }

    function markPending() {
      return pending.value = true
    }

    function markUnpending() {
      return pending.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        email: '',
        login: '',
        telegramId: null,
        avatar: {},
        active: false,
        phantoms: [],
        role: 0,
      }
    }

    function getPayload(obj) {
      const formData = new FormData()

      Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return formData
    }

    function handleForm() {
      validate(() => {
        markPending()

        let obj = {
          "user_name": fields.name,
          "user_email": fields.email,
          "role_id": fields.role,
          "user_active": true,
        }

        if (hasUser() && isPageUpdate()) {
          obj = Helper.mergeObject(obj, {
            id: fields.id,
          })
        }

        let payload = getPayload(obj)
        
        props.params.action({
          modal: props.modal,
          payload,
          options: {
            phantoms_ids: getIds(fields.phantoms),
          },
        }).then((resp) => {
          Notify.success(resp.msg)

          if (isPageUpdate()) {
            emit("onUpdate")
          } else {
            emit("onCreate")
          }
        }).catch((resp) => {
          Notify.error(resp.msg)
        }).finally(() => {
          markUnpending()
        })
      })
    }

    function hasUser() {
      return fields.id !== null
    }

    function getUser() {
      return {
        id: fields.id,
        telegram_id: fields.telegramId,
        email: fields.email,
        login: fields.login,
        name: fields.name,
        avatar: {
          host: fields.avatar?.host,
          name: fields.avatar?.name,
        },
        active: fields.active,
        role: fields.role,
        phantoms: getIds(fields.phantoms),
      }
    }

    function isPageUpdate() {
      return props.params.data.page === "update"
    }

    function isUpdateAction() {
      return hasUser()
    }

    function isCreateAction() {
      return !isUpdateAction()
    }

    function onUpdateAvatar() {
      emit("onUpdateAvatar")
    }

    return {
      handleForm,
      fields,
      v$,
      hasUser,
      getUser,
      variantsData,
      events,
      isPending,
      searchPhantoms,
      isUpdateAction,
      isCreateAction,
      onUpdateAvatar,
    }
  }
})
</script>

import NotifyBase from "@/services/NotifyBase"

export default class Notify {
    static success(msg) {
        NotifyBase.success(msg)
    }

    static info(msg) {
        NotifyBase.info(msg)
    }

    static error(msg, err) {
        NotifyBase.error(msg, err)
    }
}

<template>
  <p class="text-secondary">Разрешения, которые не связаны ни с одной группой:</p>
  <template v-if="hasPermissions()">
    <div v-for="permission in getPermissions()" :key="row.getPermissionId(permission)">
      <div class="form-check form-switch d-inline-block">
        <input class="form-check-input" type="checkbox" role="switch"
          :id="getLabelId(permission)"
          :data-permission_id="row.getPermissionId(permission)"
          :data-source_id="row.getSourceId(permission)"
          :checked="isChecked(permission)"
          @change="change"
        />
        <label class="form-check-label" :for="getLabelId(permission)" v-text="row.getPermissionName(permission)"/>
      </div>
    </div>
  </template>
  <template v-else>
    Тут пока ничего нет
  </template>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue"
import ApiBase from "@/modules/users/services/ApiBase"
import Notify from "@/services/Notify"
import element from "./services/element"
import Helper from "@/services/Helper"

export default defineComponent({
  name: "Permissions",
  components: {},
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    let permissions = ref([])

    let row = {
      getPermissionId: (item) => {
        return element.getPermissionId(item)
      },
      getSourceId: (item) => {
        return element.getSourceId(item)
      },
      getPermissionName: (item) => {
        return element.getPermissionName(item)
      },
      getPermissionCode: (item) => {
        return element.getPermissionCode(item)
      },
    }

    const fields = reactive({
      id: null,
      name: '',
      login: '',
      email: '',
      telegramId: null,
      avatar: {},
      permissions: [],
    })

    onMounted(() => {
      updateFields(props.user)
      loadPermissions()
    })

    function updateFields(query) {
      fields.id = query.id
      fields.name = query.name
      fields.login = query.login
      fields.email = query.email
      fields.telegramId = query.telegram_id
      fields.avatar = {
        host: query.avatar?.host,
        name: query.avatar?.name,
      }
      fields.permissions = query.permissions
    }

    function loadPermissions() {
      ApiBase.allPermissions({
        "is_free": true,
      }).then((resp) => {
        let data = resp.data
        
        permissions.value = data.item
      }).catch(() => {
        Notify.error("Не удалось загрузить разрешения")
      })
    }

    function getPermissions() {
      return permissions.value
    }

    function hasPermissions() {
      return !Helper.isEmptyObject(permissions.value)
    }

    function change(e) {
      if (e.target.checked === false && !confirm("Уверены?")) {
        e.target.checked = true
        return false
      }

      let sourceId = parseInt(e.target.dataset.source_id)
      let permissionId = parseInt(e.target.dataset.permission_id)

      if (e.target.checked) {
        ApiBase.userBindPermission({
          source_id: sourceId,
          permission_id: permissionId,
          user_id: fields.id,
        }).then(() => {
          Notify.success("Разрешение выдано");
        }).catch((err) => {
          e.target.checked = false

          Notify.error('Не удалось выдать разрешение', err)
        })
      } else {
        ApiBase.userUnbindPermission(fields.id, permissionId, sourceId).then(() => {
          Notify.success("Разрешение отнято");
        }).catch((err) => {
          e.target.checked = true
          
          Notify.error('Не удалось отнять разрешение', err)
        })
      }
    }

    function hasUser() {
      return fields.id !== null
    }

    function isChecked(perm) {
      return fields.permissions.includes(row.getPermissionCode(perm))
    }

    function getLabelId(perm) {
      return 'permission-' + row.getPermissionId(perm)
    }

    return {
      props,
      getPermissions,
      change,
      hasUser,
      isChecked,
      fields,
      row,
      getLabelId,
      hasPermissions,
    }
  }
})
</script>

<style scoped></style>
  
<template>
  <div id="permissions-group__find-users">
    <div>
      <label class="form-label">Поиск пользователя</label>
      <div class="input-group mb-2">
      <span class="input-group-text">
        @
      </span>
        <input type="text" class="form-control" @keyup.enter="find" placeholder="Введите имя пользователя" autocomplete="off" v-model="fields.name">
        <button class="btn" type="button" @click="find" :class="{'disabled': isPending()}">
          Найти
        </button>
        <span :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}"></span>
        <div class="invalid-feedback" v-for="error of v$.name.$silentErrors" :key="error.$uid">
          {{ error.$message }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="isPending()">
      <div class="spinner-border"></div>
    </div>
    <div v-if="showResults()">
      <div class="col-lg-12">
        <div class="card">
          <div class="list-group card-list-group">
            <template v-if="hasItems()">
              <div class="list-group-item" v-for="item of getItems()" :key="row.getId(item)">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <img :src="row.getImage(item, 80)" class="rounded" alt="Górą ty" width="40" height="40">
                  </div>
                  <div class="col">
                    {{ row.getName(item) }}
                    <div class="text-secondary" v-html="getDescription(item)"></div>
                  </div>
                  <div class="col-auto text-secondary">
                    <span class="btn-permissions" data-bs-toggle="modal" data-type="update" :data-bs-target="'#modal-permissions'" :data-group-code="getGroupCode()" :data-user='getUser({id:row.getId(item),name:row.getName(item)})'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="btn btn-x w-100 btn-icon icon-tabler-brand-pocket">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h14a2 2 0 0 1 2 2v6a9 9 0 0 1 -18 0v-6a2 2 0 0 1 2 -2" /><path d="M8 11l4 4l4 -4" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="list-group-item">
                Пользователи не найдены
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, reactive, ref} from "vue"
import Api from "./services/Api"
import {required} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core"
import User from "../../services/User"
import Helper from "@/services/Helper"
import element from "./services/element"
import UserImage from "./services/UserImage"

export default defineComponent({
  name: "FindUsers",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    let $externalResults = reactive({})
    let pending = ref(false)
    let items = ref([])
    let wasFind = ref(false)
    let row = {
      getId: (item) => {
        return element.getId(item)
      },
      getName: (item) => {
        return element.getName(item)
      },
      getEmail: (item) => {
        return element.getEmail(item)
      },
      getImage: (item, w) => {
        let avatarHost = element.getAvatarHost(item)
        let avatarName = element.getAvatarName(item)

        if (avatarHost !== null && avatarName !== null) {
          return getImage(avatarHost, avatarName, w)
        } else {
          return getImage(null, null, w)
        }
      },
    }

    const fields = reactive({
      name: null,
    })

    const rules = {
      name: {
        required,
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    function getGroupCode() {
      if (props.data?.group_code === undefined) {
        return ""
      }

      return props.data?.group_code
    }

    function getRoles() {
      if (!Helper.isArray(props.data?.roles)) {
        return []
      }

      return props.data?.roles
    }

    function getImage(host, name, w = null, h = null) {
      return UserImage.handle(host, name, w, h)
    }

    function validate(fn) {
      clearValidate()
      return v$.value.$validate().then((resp) => {
        if (resp === false) {
          return false
        }
        fn()
      })
    }

    function clearValidate() {
      v$.value.$clearExternalResults()
    }

    function find() {
      validate(() => {
        startPending();

        let query = {
          "user_name": fields.name,
        }

        if (getRoles().length > 0) {
          query = Helper.mergeObject(query, {
            "role_ids": getRoles().join(",")
          })
        }

        Api.users(query).then((resp) => {
          let items = resp?.item

          if (!Helper.isArray(items)) {
            throw new Error("Ошибка при получении пользователей")
          }

          markWasFind()
          setItems(items)
        }).finally(() => {
          stopPending()
        })
      })
    }

    function startPending() {
      return pending.value = true
    }

    function stopPending() {
      return pending.value = false
    }

    function isPending() {
      return pending.value === true
    }

    function getItems() {
      return items.value
    }

    function hasItems() {
      return items.value.length > 0
    }

    function setItems(val) {
      items.value = val
    }

    function getUser(user) {
      return User.getData({
        id: user.id,
        name: user.name,
      })
    }

    function getDescription(item) {
      let result = []

      result.push(`<b>ID</b>: ${row.getId(item)}`)

      if (row.getEmail(item).length > 0) {
        result.push(row.getEmail(item))
      }

      return result.join(", ")
    }

    function showResults() {
      return wasFind.value === true
    }

    function markWasFind() {
      wasFind.value = true
    }

    return {
      find,
      fields,
      v$,
      isPending,
      getUser,
      getItems,
      row,
      getDescription,
      hasItems,
      showResults,
      getGroupCode,
    }
  },
})
</script>

export default {
    getId: (item) => {
        return item["id"]
    },
    getName: (item) => {
        return item["name"]
    },
    getEmail: (item) => {
        return item["email"]
    },
    getAvatarHost: (item) => {
        return item["avatar_host"]
    },
    getAvatarName: (item) => {
        return item["avatar_name"]
    },
}

<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="mb-3">
        <label class="form-label">Имя</label>
        <input type="text" class="form-control" placeholder="Имя фантома" v-model="fields.name"/>
        <div v-if="v$.name.$invalid">
          <span :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}"></span>
          <div class="invalid-feedback" v-for="error of v$.name.$silentErrors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="filter-comment__select users">
        <label class="form-label">Назначенные пользователи</label>
        <Multiselect
            @change="events.onUsers"
            v-model="fields.users"
            mode="tags"
            placeholder="Не выбрано"
            noResultsText="Не найдено"
            :trackBy="'name'"
            :filter-results="false"
            :min-chars="1"
            :resolve-on-load="false"
            :object="true"
            :delay="0"
            noOptionsText="Не найдено"
            :options="async (query) => {
                return await searchUsers(query)
              }"
            searchable
        >
          <template v-slot:tag="{ option, handleTagRemove, disabled }">
            <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
              <div class="user-name">{{ option.name }}</div>
              <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                <span class="multiselect-tag-remove-icon"></span>
              </span>
            </div>
          </template>
          <template v-slot:option="{ option }">
            <div class="option-user">
              <div class="user-name"> {{ option.name }}</div>
            </div>
          </template>
        </Multiselect>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <Avatar :phantom="getPhantom()"/>
  </div>

  <div class="mt-3"></div>

  <div class="card-footer bg-transparent mt-auto p-0 pt-3">
    <div class="btn-list justify-content-end">
      <button class="btn btn-primary ms-auto" @click="handleForm" :class="{'disabled': isPending()}">
        <span class="spinner-border spinner-border-sm me-2" v-show="isPending()"></span>
        {{ params.data.btn }}
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, onMounted, ref} from "vue"
import Avatar from "../avatar/Avatar"
import {required} from "@/plugins/vuelidator"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import Notify from "@/services/Notify"
import Multiselect, {getIds, getStruct} from "@/plugins/multiselect"
import Helper from "@/services/Helper"
import ApiBase from "@/modules/users/services/ApiBase"

export default defineComponent({
  name: "Profile",
  components: {
    Avatar,
    Multiselect,
  },
  props: {
    phantom: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    modal: {
      type: Object,
      required: true
    },
  },
  setup(props,{emit}) {
    let pending = ref(false)

    const variantsData = reactive({
      users: [],
    })

    const fields = reactive(getFields())

    const rules = {
      id: {},
      name: {
        required,
      },
    }

    const v$ = useVuelidate(rules, fields)

    const events = {
      onUsers: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        fields.users = value
      },
    }

    async function searchUsers(search) {
      let query = {
        "user_name": search,
        "limit": 1000,
      }

      let resp = await ApiBase.users(query)

      return resp.data.item.map(item => ({
        value: item.id,
        name: item.name,
      }));
    }

    onMounted(() => {
      if (props?.phantom?.id) {
        users()
        updateFields()
      }
    })

    async function getUsers(id) {
      let phantoms = await ApiBase.getUsersToPhantom(id)
      
      let items = phantoms?.data?.item

      if (!items) {
        throw new Error("Ошибка при получении пользователей к фантому")
      }

      return getStruct(items, "name")
    }

    async function updateFields() {
      let id = props.phantom.id

      fields.id = props.phantom.id
      fields.name = props.phantom.name
      fields.avatar = {
        host: props.phantom.avatar?.host,
        name: props.phantom.avatar?.name,
      }
      fields.users = await getUsers(id)
    }

    async function validate(fn) {
      if (!await v$.value.$validate()) {
        return
      }

      await fn()
    }

    function isPending() {
      return pending.value === true
    }

    function markPending() {
      return pending.value = true
    }

    function markUnpending() {
      return pending.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        avatar: {},
        users: [],
      }
    }

    function getPayload(obj) {
      const formData = new FormData()

      Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return formData
    }

    function handleForm() {
      validate(() => {
        markPending()

        let obj = {
          "fantom_name": fields.name,
          "fantom_active": true,
        }

        if (getPhantom() && isPageUpdate()) {
          obj = Helper.mergeObject(obj, {
            id: fields.id,
          })
        }

        let payload = getPayload(obj)

        props.params.action({
          modal: props.modal,
          payload,
          options: {
            user_ids: getIds(fields.users),
          },
        }).then((resp) => {
          Notify.success(resp)

          if (isPageUpdate()) {
            emit("onUpdate")
          } else {
            emit("onCreate")
          }
        }).catch((resp) => {
          Notify.error(resp)
        }).finally(() => {
          markUnpending()
        })
      })
    }

    function hasPhantom() {
      return fields.id !== null
    }

    function getPhantom() {
      return {
        id: fields.id,
        name: fields.name,
        avatar: {
          host: fields.avatar?.host,
          name: fields.avatar?.name,
        },
        users: getIds(fields.users),
      }
    }

    function users() {
      let variants = [];

      variants.push(
          {
            "id": 1,
            "name": "Пользователь 1",
          },
          {
            "id": 2,
            "name": "Пользователь 2",
          },
          {
            "id": 3,
            "name": "Пользователь 3",
          },
      )

      usersMultiSelect(variants)
    }

    function usersMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.users.push({
          value: Number(obj["id"]),
          name: obj["name"],
        })
      }
    }

    function isPageUpdate() {
      return props.params.data.page === "update"
    }

    return {
      handleForm,
      fields,
      v$,
      hasPhantom,
      getPhantom,
      variantsData,
      events,
      isPending,
      searchUsers,
    }
  }
})
</script>
  
import ApiBase from "../../../services/ApiBase"
import Helper from "@/services/Helper"

export default class Api {
    static users(query) {
        query = Helper.mergeObject(query, {
            "limit": 1000,
        })

        return ApiBase.users(query).then((resp) => {
            return resp.data
        })
    }
}

<template>
  <div id="group-permissions" class="accordion">
    <p class="text-secondary">Разрешения, которые можно только отнять:</p>
    <template v-if="isReady()">
      <div class="accordion-item" v-for="(permissions, groupName) of getItems()" :key="groupName">
        <div class="accordion-header">
          <span class="accordion-text">{{ groupName }}</span>
        </div>
        <div class="accordion-collapse collapse show" v-for="permission of permissions" :key="row.getPermissionId(permission)">
          <div class="accordion-body pt-0">
            <div class="form-check form-switch d-inline-block">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="row.getUserChecked(permission)"
                  :data-source_id="row.getSourceId(permission)"
                  :data-permission_id="row.getPermissionId(permission)"
                  @click="handlePermission"
                  :disabled="row.isDisabled(permission)"
                  :id="getLabelId(row.getPermissionId(permission))"
              />
              <label class="form-check-label" :for="getLabelId(row.getPermissionId(permission))">{{ row.getPermissionName(permission) }}</label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center">
        <div class="spinner-border"></div>
      </div>
    </template>
  </div>
</template>

<script>
import './css/style.css'
import {defineComponent, onMounted, reactive, ref} from "vue"
import Api from "./services/Api"
import Helper from "@/services/Helper"
import element from "./services/element"
import ApiBase from "@/modules/users/services/ApiBase"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "GroupPermissions",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const fields = reactive({
      id: null,
    })
    let items = ref({})
    let row = {
      getPermissionId(item) {
        return element.getPermissionId(item)
      },
      getPermissionName(item) {
        return element.getPermissionName(item)
      },
      getUserChecked(item) {
        return permissionsData.value[element.getPermissionId(item)]["checked"]
      },
      isDisabled(item) {
        // не даем поменять значение, если разрешения нет или оно принудительно выставлено, что его менять нельзя
        return permissionsData.value[element.getPermissionId(item)]["checked"] === false
            || permissionsData.value[element.getPermissionId(item)]["disabled"] === true
      },
      getSourceId(item) {
        return element.getSourceId(item)
      },
    }
    let permissionsData = ref({})
    let ready = ref(false)

    async function getPermissionGroupIdsApi() {
      let groups = await Api.permissionGroups()
      let items = groups?.item

      if (!Helper.isArray(items)) {
        throw new Error("Отсутствуют данные о группах разрешений")
      }
      
      return items.map((item) => {
        return item["permission_group_id"]
      })
    }

    async function getGroupPermissionsApi() {
      let groupIds = await getPermissionGroupIdsApi()

      if (!groupIds) {
        return []
      }

      let query = {
        "permission_group_ids": groupIds.join(","),
        "user_id": fields.id
      }

      let permissions = await Api.groupPermissions(query)
      let items = permissions?.item

      if (!Helper.isArray(items)) {
        throw new Error("Отсутствуют данные о разрешениях в группах")
      }

      const result = []

      items.forEach((item) => {
        result.push({
          "group_name": item["permission_group_name"],
          "permission_id": item["permission_id"],
          "permission_name": item["permission_name"],
          "user_checked": item["permission_user_checked"],
          "source_id": item["source_id"],
          "role_checked": item["permission_role_checked"],
        })
      })
      
      return result
    }

    async function loadGroupPermissions() {
      let items = await getGroupPermissionsApi()
      let result = {}

      items.forEach((value) => {
        let key = value["group_name"]
        
        if (!result[key]) {
          result[key] = []
        }

        result[key].push(value)

        setPermissionData(value["permission_id"], {
          // пользовательские разрешения и разрешения через роли учитываем, как активные
          checked: value["user_checked"] || value["role_checked"],
          disabled: value["role_checked"],
        })
      })

      setItems(result)
    }

    function setPermissionData(key, data) {
      permissionsData.value[key] = data
    }

    onMounted(async () => {
      updateFields(props.user)
      await loadGroupPermissions()
      markReady()
    })

    function updateFields(query) {
      fields.id = query.id
    }

    function setItems(val) {
      items.value = val
    }

    function getItems() {
      return items.value
    }

    function handlePermission(e) {
      if (!confirm("Уверены?")) {
        e.target.checked = true
        return false
      }

      //let checked = false
      let sourceId = parseInt(e.target.dataset.source_id)
      let permissionId = parseInt(e.target.dataset.permission_id)

      if (e.target.checked) {
        //checked = true
      }

      permissionsData.value[permissionId]["checked"] = e.target.checked

      ApiBase.userUnbindPermission(
          fields.id,
          permissionId,
          sourceId,
      ).then(() => {
        Notify.success("Разрешение отнято")
      }).catch((err) => {
        e.target.checked = true
        permissionsData.value[permissionId]["checked"] = e.target.checked

        Notify.error('Ошибка отнятия разрешения', err)
      })

      /*if (checked) {
        Notify.info("Из этого меню выдавать разрешения запрещено")
      } else {

      }*/
    }

    function markReady() {
      ready.value = true
    }

    function isReady() {
      return ready.value === true
    }

    function getLabelId(permissionId) {
      return 'permission-' + permissionId
    }

    return {
      getItems,
      row,
      handlePermission,
      permissionsData,
      isReady,
      getLabelId,
    }
  }
})
</script>

<style>

</style>

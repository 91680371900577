<template>
  <div ref="createModal" class="modal fade" id="modal-permissions" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle() }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="isNotLoad()">
              <template v-if="hasPermissions()">
                <div class="mb-2" v-for="permission of getPermissions()" :key="row.getPermissionId(permission)">
                  <div class="form-check form-switch d-inline-block">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="row.getUserChecked(permission)"
                        :data-source_id="row.getSourceId(permission)"
                        :data-permission_id="row.getPermissionId(permission)"
                        @click="handlePermission"
                        v-model='permissionsData[row.getPermissionId(permission)]["checked"]'
                        :id="getLabelId(row.getPermissionId(permission))"
                    />
                    <label class="form-check-label" :for="getLabelId(row.getPermissionId(permission))">{{ row.getPermissionName(permission) }}</label>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-center">
                  Доступных разрешений не найдено
                </div>
              </template>
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <div class="spinner-border"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {reactive, ref, onMounted, defineComponent} from 'vue'
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import ApiBase from "@/modules/users/services/ApiBase"
import Api from "./services/Api"
import Helper from "@/services/Helper"
import element from "./services/element"
import Notify from "@/services/Notify"
import { eventBus } from '@/plugins/event_bus/event_bus'
import {eventBindUndind} from "./services/data"

export default defineComponent({
  name: "Permissions",
  components: {},
  setup(props, {emit}) {
    const createModal = ref(null);
    const fields = reactive(getFields())

    let load = ref(false)
    let params = ref(getParams())
    let bootModal = null
    let permissions = ref({})
    let permissionsData = ref({})
    let row = {
      getPermissionId(item) {
        return element.getPermissionId(item)
      },
      getPermissionName(item) {
        return element.getPermissionName(item)
      },
      getUserChecked(item) {
        return permissionsData.value[element.getPermissionId(item)]["checked"]
      },
      getSourceId(item) {
        return element.getSourceId(item)
      },
    }

    function getParams() {
      return {
        data: {
          title: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function isNotLoad() {
      return load.value === false
    }

    function markLoad() {
      return load.value = true
    }

    function markUnload() {
      return load.value = false
    }

    function getFields() {
      return {
        id: null,
        name: '',
        groupCode: '',
        permissions: [],
      }
    }

    function closeModal() {
      clearForm()
    }

    async function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      // это оставлено на случай, если пригодится такое условие
      // не стал выпиливать, так как это было уже написано ранее
      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }

      await loadGroupPermissions()
    }

    function setParams(val) {
      params.value = val
    }

    function create() {}

    function update(event) {
      const button = event.relatedTarget
      const user = JSON.parse(button.getAttribute('data-user'))
      const groupCode = button.getAttribute('data-group-code')

      setStaticFields({
        id: user.id,
        name: user.name,
        groupCode: groupCode,
      })

      ApiBase.user(user.id).then((resp) => {
        let item = resp.data?.item

        setDynamicFields({
          permissions: item?.permissions
        })
      }).catch(() => {})
      .finally(() => {
        markUnload()
      })
    }

    function setStaticFields({id, name, groupCode}) {
      fields.id = id
      fields.name = name
      fields.groupCode = groupCode
    }

    function setDynamicFields({permissions}) {
      fields.permissions = permissions
    }

    function preShowModal() {
      markLoad()
      resetParams()
    }

    onMounted(() => {
      bootModal = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal)
      createModal.value.addEventListener('show.bs.modal', preShowModal)
      createModal.value.addEventListener('hidden.bs.modal', closeModal)
    })

    function clearForm() {
      let items = getFields()
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function getUser() {
      return {
        id: fields.id,
        name: fields.name,
        permissions: fields.permissions,
      }
    }

    function hasUser() {
      return fields.id !== null
    }

    function getTitle() {
      let result = ""

      result += params.value.data.title

      if (hasUser()) {
        result += ": "
        result += fields.name
      }

      return result
    }

    function onUpdate() {
      emit("onUpdate")
    }

    function onCreate() {
      emit("onCreate")
    }

    async function getGroupPermissionsApi() {
      let query = {
        "permission_group_codes": fields.groupCode,
        "user_id": fields.id
      }

      let permissions = await Api.groupPermissions(query)
      let items = permissions?.item

      if (!Helper.isArray(items)) {
        throw new Error("Отсутствуют данные о разрешениях в группах")
      }

      const result = []
      items.forEach((item) => {
        result.push({
          "group_name": item["permission_group_name"],
          "permission_id": item["permission_id"],
          "permission_name": item["permission_name"],
          "user_checked": item["permission_user_checked"],
          "source_id": item["source_id"],
        })
      })

      return result
    }

    async function loadGroupPermissions() {
      let items = await getGroupPermissionsApi()
      let result = []

      items.forEach((value) => {
        result.push(value)

        setPermissionData(value["permission_id"], {
          checked: value["user_checked"],
        })
      })

      setPermissions(result)
    }

    function setPermissionData(key, data) {
      permissionsData.value[key] = data
    }

    function setPermissions(val) {
      permissions.value = val
    }

    function getPermissions() {
      return permissions.value
    }

    function getLabelId(permissionId) {
      return 'permission-' + permissionId
    }

    function handlePermission(e) {
      if (e.target.checked === false && !confirm("Уверены?")) {
        e.target.checked = true
        return false
      }

      let checked = false
      let sourceId = parseInt(e.target.dataset.source_id)
      let permissionId = parseInt(e.target.dataset.permission_id)

      if (e.target.checked) {
        checked = true
      }

      if (checked) {
        ApiBase.userBindPermission({
          source_id: sourceId,
          permission_id: permissionId,
          user_id: fields.id,
        }).then(() => {
          runEvent("bind")
          Notify.success("Разрешение выдано")
        }).catch((err) => {
          e.target.checked = false
          Notify.error('Ошибка выдачи разрешения', err)
        })
      } else {
        ApiBase.userUnbindPermission(
            fields.id,
            permissionId,
            sourceId,
        ).then(() => {
          runEvent("unbind")
          Notify.success("Разрешение отнято")
        }).catch((err) => {
          e.target.checked = true
          Notify.error('Ошибка отнятия разрешения', err)
        })
      }
    }

    function runEvent(action) {
      eventBus.emit(eventBindUndind, {
        "action": action,
      })
    }

    function hasPermissions() {
      return !Helper.isEmptyObject(permissions.value)
    }

    return {
      isNotLoad,
      fields,
      createModal,
      params,
      getUser,
      getTitle,
      bootModal,
      onUpdate,
      onCreate,
      getPermissions,
      row,
      permissionsData,
      getLabelId,
      handlePermission,
      hasPermissions,
    }
  }
})
</script>

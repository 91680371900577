import ApiBase from "@/modules/users/services/ApiBase"
import ConvertQuery from "./ConvertQuery"

export default class Api {
    static async users(query) {
        let q = ConvertQuery.handle(query)

        const resp = await ApiBase.users(q)

        return resp.data
    }

    static async permissions(query) {
        const resp = await ApiBase.allPermissions(query)
        return resp.data
    }
}

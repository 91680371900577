<template>
  <header class="navbar-expand-md">
    <div class="navbar-collapse collapse" id="navbar-menu" style="" :class="{ 'dev': isEnvLocal }">
      <div class="navbar navbar-light">
        <div class="container-xl">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Channels' }">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-category" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4h6v6h-6z"></path>
                    <path d="M14 4h6v6h-6z"></path>
                    <path d="M4 14h6v6h-6z"></path>
                    <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  </svg>
                </span>
                <span class="nav-link-title">
                  Каналы
                </span>
              </router-link>
            </li>
            <Posts />
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Search' }">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-database-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"></path>
                    <path d="M4 6v6c0 1.657 3.582 3 8 3m8 -3.5v-5.5"></path>
                    <path d="M4 12v6c0 1.657 3.582 3 8 3"></path>
                    <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                    <path d="M20.2 20.2l1.8 1.8"></path>
                  </svg>
                </span>
                <span class="nav-link-title">
                  Глобальный поиск
                </span>
              </router-link>
            </li>
            <Comments />
            <Users />
            <li class="nav-item dropdown" @click="clickMenuMainItem()" ref="menuMainItem">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                </span>
                <span class="nav-link-title">
                  Ещё
                </span>
              </a>
              <div class="dropdown-menu">
                <div class="dropdown-menu-columns">
                  <div class="dropdown-menu-column">
                    <Shops @click="clickItem()"/>
                    <Catalog @click="clickItem()"/>
                    <Ranking @click="clickItem()"/>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref} from "vue"
import Posts from "@/components/menu/components/Posts"
import Ranking from "@/components/menu/components/Ranking"
import Catalog from "@/components/menu/components/Catalog"
import Comments from "@/components/menu/components/Comments"
import Users from "@/components/menu/components/Users"
import Shops from "@/components/menu/components/Shops"
import Helper from "@/services/Helper"

export default defineComponent({
  name: "Menu",
  components: {
    Posts,
    Ranking,
    Catalog,
    Comments,
    Users,
    Shops,
  },
  setup() {
    let menuMainItem = ref(null)
    let isEnvLocal = Helper.isEnvLocal()
    let isClick = ref(false)

    function clickItem() {
      isClick.value = true
    }

    function clickMenuMainItem() {
      if (isClick.value) {
        isClick.value = false
        menuMainItem.value.click()
      }
    }

    return {
      isEnvLocal,
      clickItem,
      clickMenuMainItem,
      menuMainItem,
    }
  },
})
</script>

<style scoped></style>

import RoleApiBase from "../services/ApiBase"

export default {
    namespaced: true,
    state: {
        userData: {},
        auth: false,
        tryAuth: false,
        permissions: [],
    },
    getters: {
        getUserData: (state) => {
            return state.userData
        },
        getAuth: (state) => {
            return state.auth
        },
        getTryAuth: (state) => {
            return state.tryAuth
        },
        getPermissions: (state) => {
            return state.permissions
        },
    },
    mutations: {
        setUserData: (state, payload) => {
            state.userData = payload
        },
        setAuth: (state, payload) => {
            state.auth = payload
        },
        markTryAuth: (state) => {
            state.tryAuth = true
        },
        setPermissions: (state, payload) => {
            state.permissions = payload
        },
    },
    actions: {
        setPermissions(state) {
            return new Promise((resolve) => {
                RoleApiBase.allPermissions().then((resp) => {
                    state.commit('setPermissions', resp.data["items"])
                    resolve();
                });
            });
        }
    },
}
